import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { SPECIAL_HOST_IDS } from 'utils/constants';
// import path from 'path';

// const fontPath = path.join(__dirname, '../../font/NotoSansSC-Regular.otf');

Font.register('https://fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.ttf', { family: 'cwTeXKai' });
// Font.register(fontPath, { family: 'Noto Sans' });

// Register Font
// Font.register('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', {
//   family: 'Roboto'
// });

const calculateTotalCharges = data => {
  const roomRate = data.roomRate || 0.0;
  const stripeCharges = data.stripeCharges || 0.0;
  const cleaningFee = data.cleaningFee || 0.0;
  const extraGuestFee = data.extraGuestFee || 0.0;
  const earlyCheckInFee = data.earlyCheckInFee || 0.0;
  const lateCheckOutFee = data.lateCheckOutFee || 0.0;
  const shuttleFee = data.shuttleFee || 0.0;
  const transportFee = data.transportFee || 0.0;
  const breakfastFee = data.breakfastFee || 0.0;
  const lunchFee = data.lunchFee || 0.0;
  const dinnerFee = data.dinnerFee || 0.0;
  const otherFee = data.otherFee || 0.0;
  const addOnFee = data.addOnData
    ? data.addOnData.reduce((total, addOn) => {
        return total + addOn.value;
      }, 0.0)
    : 0.0;
  const tourismTax = data.tourismTax || 0.0;
  const heritageTax = data.heritageTax || 0.0;
  const otherTax = data.otherTax || 0.0;
  const otaTax = data.otaTax || 0.0;

  return (
    roomRate +
    stripeCharges +
    cleaningFee +
    extraGuestFee +
    earlyCheckInFee +
    lateCheckOutFee +
    shuttleFee +
    transportFee +
    breakfastFee +
    lunchFee +
    dinnerFee +
    otherFee +
    tourismTax +
    heritageTax +
    otherTax +
    otaTax +
    addOnFee
  );
};

const calculateExtraLines = data => {
  const TOTAL_NUMBER_OF_FEES_AND_TAX = 13 + data.addOnData.length; // Update this when you add or remove fee

  const cleaningFeeLine = data.cleaningFee ? 1 : 0;
  const extraGuestFeeLine = data.extraGuestFee ? 1 : 0;
  const earlyCheckInFeeLine = data.earlyCheckInFee ? 1 : 0;
  const lateCheckOutFeeLine = data.lateCheckOutFee ? 1 : 0;
  const shuttleFeeLine = data.shuttleFee ? 1 : 0;
  const transportFeeLine = data.transportFee ? 1 : 0;
  const breakfastFeeLine = data.breakfastFee ? 1 : 0;
  const lunchFeeLine = data.lunchFee ? 1 : 0;
  const dinnerFeeLine = data.dinnerFee ? 1 : 0;
  const otherFeeLine = data.otherFee ? 1 : 0;
  const addOnFeeLine = data.addOnData ? data.addOnData.length : 0;
  const tourismTaxLine = data.tourismTax ? 1 : 0;
  const heritageTaxLine = data.heritageTax ? 1 : 0;
  const otherTaxLine = data.otherTax ? 1 : 0;
  const otaTaxLine = data.otaTax ? 1 : 0;

  return (
    TOTAL_NUMBER_OF_FEES_AND_TAX -
    (cleaningFeeLine +
      extraGuestFeeLine +
      earlyCheckInFeeLine +
      lateCheckOutFeeLine +
      shuttleFeeLine +
      transportFeeLine +
      breakfastFeeLine +
      lunchFeeLine +
      dinnerFeeLine +
      otherFeeLine +
      addOnFeeLine +
      tourismTaxLine +
      heritageTaxLine +
      otherTaxLine +
      otaTaxLine)
  );
};

class TaxInvoice extends Component {
  static propTypes = {
    data: PropTypes.object,
    currency: PropTypes.string
  };

  handleOnClickPdfBtn = url => {
    window.open(url, '_blank');
  };

  constructCheckOutTimeText = (checkOutTime = '12:00') => {
    const splittedCheckOutTime = checkOutTime.split(':');
    if (splittedCheckOutTime.length > 0) {
      const hourValue = Number(splittedCheckOutTime[0]);
      const minuteValue = Number(splittedCheckOutTime[1]);
      if (hourValue > 12) {
        return `${hourValue - 12}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} PM`;
      } else if (hourValue === 12) {
        return `${hourValue}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} PM`;
      } else if (hourValue === 0) {
        return `12:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} AM`;
      } else {
        return `${hourValue}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} AM`;
      }
    }
    return `${checkOutTime} PM`;
  };

  checkIfDeductDeposit = transaction => {
    return transaction.remarks && transaction.remarks.toLowerCase().includes('deposit deducted');
  };

  extractDeductedDeposit = transaction => {
    if (Array.isArray(transaction)) {
      return transaction.reduce((total, trans) => {
        if (trans && trans.remarks && trans.remarks.toLowerCase().includes('deposit deducted')) {
          return total + (trans.amount || 0);
        }
        return total;
      }, 0);
    } else {
      return transaction && transaction.remarks && transaction.remarks.toLowerCase().includes('deposit deducted') ? transaction.amount || 0 : 0;
    }
  };

  constructChargeTypeTextPdf = (transaction, confirmationCode) => {
    switch (transaction.type) {
      case 'charges':
        return confirmationCode;
      case 'payment':
        if (this.checkIfDeductDeposit(transaction)) {
          return 'Deposit';
        } else {
          return 'Payment';
        }
      case 'deposit':
        return 'Deposit';
      case 'tax':
        return 'Tax';
      case 'addon':
        return 'Add On';
      default:
        break;
    }
  };

  constructChargeSubTypeTextPdf = transaction => {
    switch (transaction.subType) {
      case 'rental':
        if (this.checkIfDeductDeposit(transaction)) {
          return 'Deposit Deducted';
        } else {
          return 'Room Rate';
        }
      case 'cleaning':
        return 'Cleaning Fee';
      case 'extraGuest':
        return 'Extra Guest Fee';
      case 'earlyCheckIn':
        return 'Early Check In Fee';
      case 'lateCheckOut':
        return 'Late Check Out Fee';
      case 'shuttle':
        return 'Shuttle Fee';
      case 'transportation':
        return 'Transportation Fee';
      case 'breakfast':
        return 'Breakfast Fee';
      case 'lunch':
        return 'Lunch Fee';
      case 'dinner':
        return 'Dinner Fee';
      case 'other':
        return 'Other Fee';
      case 'collect':
        return 'Deposit Collected';
      case 'refund':
        return 'Deposit Refunded';
      case 'sst':
        return 'Sales & Service Tax';
      case 'tourism':
        return 'Tourism Tax';
      case 'heritage':
        return 'Heritage Tax';
      case 'ota':
        return 'OTA Tax';
      case 'otherTax':
        return ' Other Tax';
      case 'localGovernmentTax':
        return 'Local Government Tax';
      default:
        break;
    }
  };

  constructDataForTaxInvoicePdf = reservation => {
    const {
      platform,
      charges,
      depositCollected,
      depositRefunded,
      endDate,
      guestDetails,
      payment,
      remarks,
      startDate,
      taxes,
      unit,
      addOns,
      transactions
      // priceDetails
    } = reservation;
    const { getGuestNationality } = this.props;

    const filteredPaymentMethods = payment.details.reduce((reducedPaymentArray, current) => {
      const { method, cardNo } = current;

      if (method !== 'creditCard' && !reducedPaymentArray.find(payment => payment.method === method)) {
        return [...reducedPaymentArray, current];
      }

      if (!reducedPaymentArray.find(payment => payment.cardNo === cardNo)) {
        return [...reducedPaymentArray, current];
      }

      return reducedPaymentArray;
    }, []);

    const paymentMethod = filteredPaymentMethods
      .map(paymentMethod => {
        const { method, cardNo } = paymentMethod;

        switch (method) {
          case 'cash':
            return 'Cash';
          case 'others':
            return 'Others';
          case 'creditCard':
            return String(cardNo)
              .slice(-4)
              .padStart(16, 'X');
          default:
            return '';
        }
      })
      .join(', ');

    const addOnData = Object.keys(addOns).reduce((newAddOn, addOnKey) => {
      const addOnAmount = addOns[addOnKey];
      return [...newAddOn, { label: addOnKey, value: addOnAmount }];
    }, '');

    return {
      propertyName: unit.roomType.property.name || '',
      roomType: unit.roomType.name || '',
      checkOutTime: unit.roomType.property.checkOutTime || '',
      unitName: unit.name || '',
      checkIn: startDate || '',
      checkOut: endDate || '',
      bookingSource: platform || '',
      noPax: guestDetails.numberOfPax || 0,
      remarks: remarks || '',
      depositCollected: depositCollected || 0,
      depositRefunded: depositRefunded || 0,
      depositDeducted: this.extractDeductedDeposit(transactions) || undefined,
      roomRate:
        platform === 'hotelBookingEngine' || platform === 'bookingEngine'
          ? transactions.reduce((sum, t) => (t.type === 'charges' && t.subType === 'rental' ? sum + t.amount + t.paymentCharges : sum), 0)
          : (charges && charges.rental) || 0,
      // stripeCharges: (charges && charges.stripeCharges) || 0,
      cleaningFee:
        platform === 'hotelBookingEngine' || platform === 'bookingEngine'
          ? transactions.reduce((sum, t) => (t.type === 'charges' && t.subType === 'cleaning' ? sum + t.amount + t.paymentCharges : sum), 0)
          : (charges && charges.cleaning) || 0,
      extraGuestFee: (charges && charges.extraGuest) || 0,
      earlyCheckInFee: (charges && charges.earlyCheckIn) || 0,
      lateCheckOutFee: (charges && charges.lateCheckOut) || 0,
      shuttleFee: (charges && charges.shuttle) || 0,
      transportFee: (charges && charges.transportation) || 0,
      breakfastFee: (charges && charges.breakfast) || 0,
      lunchFee: (charges && charges.lunch) || 0,
      dinnerFee: (charges && charges.dinner) || 0,
      otherFee: (charges && charges.other) || 0,
      sstTax: (taxes && taxes.sst) || 0,
      tourismTax: (taxes && taxes.tourism) || 0,
      heritageTax: (taxes && taxes.heritage) || 0,
      // otherTax: (taxes && taxes.otherTax) || 0,
      otaTax: (taxes && taxes.ota) || 0,
      guestName: (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
      hostEmail: unit.roomType.property.host.email || '-',
      hostContactNo: unit.roomType.property.host.contactNo || '-',
      hostData: unit.roomType.property.host || {},
      nationality: guestDetails.userProfile && getGuestNationality(guestDetails.userProfile.nationality),
      paymentReceived: payment.total,
      paymentMethod,
      addOnData,
      transactions
    };
  };

  render() {
    const { data, reservation, currency, host } = this.props;
    const todayDate = new Date().toISOString().split('T')[0];

    const invoiceData = {
      ...this.constructDataForTaxInvoicePdf(reservation),
      ...data
    };

    const styles = StyleSheet.create({
      page: {
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        width: '100%'
      },
      image: {
        width: 80,
        height: 80,
        marginLeft: 0
      },
      fontSize: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingRight: 10,
        paddingLeft: 10
      },
      fontPolicy: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'left',
        fontSize: 8,
        paddingRight: 10,
        paddingLeft: 10
      },
      text: {
        color: '#212121',
        width: '30%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      addressText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      titleText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 18
      },
      TableText: {
        color: '#212121',
        width: '100%',
        marginLeft: 13,
        textAlign: 'left',
        fontSize: 20,
        paddingTop: 10
      },
      flex: {
        flexDirection: 'row',
        width: '100%',
        fontSize: 10,
        justifyContent: 'space-between',
        marginTop: 40,
        marginLeft: 210
      },
      row: {
        flexDirection: 'row'
      },
      fields: {
        color: '#212121',
        width: '98%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10
      },
      fieldsOne: {
        color: '#212121',
        width: '45%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 10
      },
      fieldsTwo: {
        color: '#212121',
        width: '50%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 10
      },
      fieldsThree: {
        color: '#212121',
        width: '100%',
        textAlign: 'left',
        fontSize: 10,
        paddingBottom: 5
      },
      fieldsEmpty: {
        width: '5%'
      },
      fieldsLogo: {
        width: '20%',
        textAlign: 'left',
        paddingTop: 10,
        paddingBottom: 5
      },
      fieldsHostDetails: {
        fontSize: 10,
        width: '80%',
        textAlign: 'left',
        paddingLeft: 10,
        paddingTop: 10,
        paddingRight: 10,
        whiteSpace: 'normal'
      },
      fieldsFooter: {
        width: '98%',
        textAlign: 'left',
        fontSize: 10,
        paddingLeft: 10
      },
      borderBottom: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        borderColor: 'black'
      },
      borderTop: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        alignItems: 'stretch',
        borderColor: 'black'
      },
      borderRight: {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderColor: 'black'
      },
      borderLeft: {
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderColor: 'black'
      },
      groupFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 15,
        paddingLeft: 15,
        margin: 'auto'
      },
      groupFieldsTwo: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingRight: 15,
        paddingLeft: 15,
        margin: 'auto'
      },
      guestSig: {
        textAlign: 'center',
        width: '100%',
        paddingRight: '3%'
      },
      paddingBtm: {
        paddingBottom: 5
      },
      Policy: {
        paddingTop: 10,
        width: '100%'
      },
      lastPolicyFont: {
        paddingTop: 5,
        width: '100%',
        fontSize: 8,
        paddingRight: 10,
        paddingLeft: 10
      },
      guestName: {
        // fontFamily: ['Roboto', 'STHeiti Medium', 'AppleGothic'],
        paddingBottom: 5
      }
    });
    const address = invoiceData.hostData.useHostDetails
      ? invoiceData.hostData.street +
        (invoiceData.hostData.city ? ', ' + invoiceData.hostData.city : '') +
        (invoiceData.hostData.zipCode ? ', ' + invoiceData.hostData.zipCode : '') +
        (invoiceData.hostData.state ? ', ' + invoiceData.hostData.state : '') +
        (invoiceData.hostData.country ? ', ' + invoiceData.hostData.country : '')
      : invoiceData.street +
        (invoiceData.city ? ', ' + invoiceData.city : '') +
        (invoiceData.zipCode ? ', ' + invoiceData.zipCode : '') +
        (invoiceData.state ? ', ' + invoiceData.state : '') +
        (invoiceData.country ? ', ' + invoiceData.country : '');
    const paymentReceived = invoiceData.paymentReceived === null ? 0.0 : invoiceData.paymentReceived;
    const totalCharges = calculateTotalCharges(invoiceData);
    const extraLines = calculateExtraLines(invoiceData);
    const totalChargesWithSST = totalCharges + (invoiceData.sstTax ? invoiceData.sstTax : 0);

    let doc = (
      <Document>
        <Page size="A4" wrap>
          <View>
            <View style={styles.page}>
              <View style={styles.groupFields}>
                <View style={styles.fieldsLogo}>
                  <Image
                    style={styles.image}
                    src={
                      invoiceData.image
                        ? invoiceData.image
                        : 'https://dashboard-operator-image.s3.amazonaws.com/3386bffc-75c9-4390-a670-fc11ed8febe1_blanksquare.png'
                    }
                  />
                </View>
                <View style={styles.fieldsHostDetails}>
                  <Text style={{ ...styles.paddingBtm, fontSize: 12 }}>
                    {invoiceData.hostData.useHostDetails ? invoiceData.hostData.name : invoiceData.propertyName || ''}
                  </Text>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Address</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '83%' }}>
                      <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{address}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Email</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '85%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.hostEmail || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Contact Number</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '85%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.hostContactNo || '-'}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text style={styles.titleText}>INVOICE</Text>
              <View style={styles.groupFieldsTwo}>
                <View style={styles.fieldsOne}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Attn</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={{ fontFamily: 'cwTeXKai', paddingBottom: 5 }}>{invoiceData.guestName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={{ fontFamily: 'cwTeXKai', paddingBottom: 5 }}>Guest Name</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.guestName}>{invoiceData.guestName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Nationality</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.nationality || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Booking Source</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={{ ...styles.paddingBtm, textTransform: 'capitalize' }}>{invoiceData.bookingSource || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Confirmation Code</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.confirmationCode || '-'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.fieldsEmpty} />
                <View style={styles.fieldsTwo}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Room No</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.unitName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Rate</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.roomType || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>No of pax</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.noPax || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Date of Arrival</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.checkIn || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Date of Departure</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.checkOut || '-'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.fieldsThree}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Remarks</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '78%' }}>
                      <Text style={styles.paddingBtm}>{invoiceData.remarks || '-'}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '97%',
                    height: 23,
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      width: '15%',
                      textAlign: 'center',
                      paddingTop: 7,
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Date
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: '15%',
                      textAlign: 'center',
                      paddingTop: 7,
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Ref No.
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      paddingTop: 7,
                      paddingLeft: 22,
                      width: '55%',
                      textAlign: 'left',
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Particulars
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      paddingTop: 7,
                      width: '15%',
                      textAlign: 'center',
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {currency ? currency : 'RM'}
                  </Text>
                </View>
              </View>
              {!SPECIAL_HOST_IDS.includes(host) && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    >
                      {invoiceData.checkIn}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    >
                      {invoiceData.confirmationCode}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Room Rate
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.roomRate ? invoiceData.roomRate.toFixed(2) : '0.00'}
                    </Text>
                  </View>
                </View>
              )}
              {/* {invoiceData.stripeCharges === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Stripe Charges
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.stripeCharges ? invoiceData.stripeCharges.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )} */}
              {SPECIAL_HOST_IDS.includes(host) &&
                invoiceData.transactions &&
                invoiceData.transactions.length > 0 &&
                invoiceData.transactions.map((transaction, index) => (
                  <View key={transaction._id || index} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <View
                      className="table"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '95%',
                        ...styles.fontSize
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      >
                        {transaction.date || '-'}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      >
                        {this.constructChargeTypeTextPdf(transaction, invoiceData.confirmationCode)}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          paddingLeft: 22,
                          paddingTop: 7,
                          width: '55%',
                          textAlign: 'left',
                          ...styles.borderLeft
                        }}
                      >
                        {this.constructChargeSubTypeTextPdf(transaction)}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft,
                          ...styles.borderRight
                        }}
                      >
                        {transaction.amount ? transaction.amount.toFixed(2) : '0.00'}
                      </Text>
                    </View>
                  </View>
                ))}
              {invoiceData.cleaningFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Cleaning Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.cleaningFee ? invoiceData.cleaningFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.extraGuestFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Extra Guest Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.extraGuestFee ? invoiceData.extraGuestFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.earlyCheckInFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Early Check-in Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.earlyCheckInFee ? invoiceData.earlyCheckInFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.lateCheckOutFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Late Check-out Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.lateCheckOutFee ? invoiceData.lateCheckOutFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.shuttleFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Shuttle Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.shuttleFee ? invoiceData.shuttleFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.transportFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Transportation Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.transportFee ? invoiceData.transportFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.breakfastFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Breakfast
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.breakfastFee ? invoiceData.breakfastFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.lunchFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Lunch
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.lunchFee ? invoiceData.lunchFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.dinnerFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Dinner
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.dinnerFee ? invoiceData.dinnerFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.otherFee === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Other
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.otherFee ? invoiceData.otherFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {!invoiceData.addOnData && invoiceData.addOnData.length === 0
                ? null
                : invoiceData.addOnData.map(addOn => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%'
                        }}
                      >
                        <View
                          className="table"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '95%',
                            ...styles.fontSize
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 8,
                              paddingTop: 7,
                              width: '15%',
                              ...styles.borderLeft
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 8,
                              paddingTop: 7,
                              width: '15%',
                              ...styles.borderLeft
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 8,
                              paddingLeft: 22,
                              paddingTop: 7,
                              width: '55%',
                              textAlign: 'left',
                              ...styles.borderLeft
                            }}
                          >
                            {'Add On - ' + addOn.label.toString() || 'Add On'}
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              paddingTop: 7,
                              width: '15%',
                              ...styles.borderLeft,
                              ...styles.borderRight
                            }}
                          >
                            {addOn.value ? addOn.value.toFixed(2) : 0.0}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
              {invoiceData.tourismTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Tourism Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.tourismTax ? invoiceData.tourismTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {invoiceData.heritageTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Heritage Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        width: '15%',
                        paddingTop: 7,
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.heritageTax ? invoiceData.heritageTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {/* {invoiceData.otherTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Other Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.otherTax ? invoiceData.otherTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )} */}
              {invoiceData.otaTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      OTA Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        width: '15%',
                        paddingTop: 7,
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {invoiceData.otaTax ? invoiceData.otaTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {new Array(extraLines || 0).fill(undefined).map((val, i) => {
                return (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%'
                    }}
                    key={i}
                  >
                    <View
                      className="table"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '95%',
                        ...styles.fontSize
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 8,
                          paddingLeft: 22,
                          paddingTop: 7,
                          width: '55%',
                          textAlign: 'left',
                          ...styles.borderLeft
                        }}
                      >
                        &nbsp;
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft,
                          ...styles.borderRight
                        }}
                      />
                    </View>
                  </View>
                );
              })}

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right',
                      ...styles.borderTop
                    }}
                  >
                    Total :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {totalCharges ? totalCharges.toFixed(2) : 0.0}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Total Sales & Service Tax :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {invoiceData.sstTax ? invoiceData.sstTax.toFixed(2) : '0.00'}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Total Payable :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderBottom,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {totalChargesWithSST ? totalChargesWithSST.toFixed(2) : 0.0}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Payment :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {paymentReceived ? paymentReceived.toFixed(2) : '0.00'}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Balance :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderBottom,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {((totalChargesWithSST || 0.0) - (paymentReceived || 0.0)).toFixed(2) < (0.0).toFixed(2)
                      ? (0.0).toFixed(2)
                      : ((totalChargesWithSST || 0.0) - (paymentReceived || 0.0)).toFixed(2)}
                  </Text>
                </View>
              </View>
              <View style={styles.fields}>
                <Text style={{ ...styles.paddingBtm, fontSize: 10 }}>
                  Security Deposit Collected : {currency ? currency : 'RM'}{' '}
                  {invoiceData.depositCollected
                    ? invoiceData.depositDeducted
                      ? (invoiceData.depositCollected - invoiceData.depositDeducted).toFixed(2)
                      : invoiceData.depositCollected.toFixed(2)
                    : '0.00'}
                </Text>
                <Text style={{ ...styles.paddingBtm, fontSize: 10, borderBottom: '1px solid black' }}>
                  Security Deposit Refunded : {currency ? currency : 'RM'}{' '}
                  {invoiceData.depositRefunded ? invoiceData.depositRefunded.toFixed(2) : '0.00'}
                </Text>
                {!!invoiceData.paymentMethod && (
                  <Text style={{ ...styles.paddingBtm, fontSize: 8 }}>Payment Method(s): {invoiceData.paymentMethod}</Text>
                )}
              </View>
              <View style={styles.Policy}>
                <Text style={styles.fontPolicy}>{invoiceData.finePrint}</Text>
              </View>
              <View style={styles.flex}>
                <View style={styles.guestSig}>
                  <Text>___________________________</Text>
                  <Text>Guest Signature</Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  fontSize: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingTop: 10
                }}
              >
                <View
                  style={{
                    width: '50%'
                  }}
                >
                  <Text>Receptionist : {invoiceData.createdBy.firstName + ' ' + invoiceData.createdBy.lastName}</Text>
                  <Text>Date : {todayDate}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    return (
      <BlobProvider document={doc} fileName={'invoice.pdf'}>
        {({ blob, url, loading, error }) => (
          <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
            {loading ? 'Loading' : intl.get('reservations.csvModal.invoice').d('Invoice')}
          </Button>
        )}
      </BlobProvider>
    );
  }
}
export default TaxInvoice;
