import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { getConstants } from 'utils/apis/constants';
import { SPECIAL_HOST_IDS } from 'utils/constants';
import { CONTACT_NUMBER_REGEX, EMAIL_REGEX } from 'utils/constants';
import { Form, Input, Icon, Button, Select, Popconfirm, Col, Row, Radio } from 'antd';

import './DynamicFieldSet.css';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const Option = Select.Option;
let uuid = 0;

class DynamicFieldSet extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      countryOptions: [],
      stateOptions: [],
      sponsorOptions: [],
      update: false,
      gender: undefined,
      sellingMode: undefined,
      identity: 'student',
      sponsoredBy: undefined
    };
  }

  remove = k => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('keys').filter(key => key !== k);

    // can use data-binding to set
    form.setFieldsValue({
      keys: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [...fieldsToValidate, `firstName[${key}]`, `lastName[${key}]`, `nationality[${key}]`, `contactNo[${key}]`];
      }, []),
      { first: true },
      (err, values) => {
        const newValues = form.getFieldsValue();
        newValues.keys = newKeys; //in case setFieldsValue sets keys after the getFieldsValue
        onChange(newValues, !err);
      }
    );
  };

  add = () => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(uuid);
    uuid++;
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
    onChange(newValues, false);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    // e.preventDefault();
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  handleIdentityChange = e => {
    const { form, onChange } = this.props;
    this.setState({
      identity: e.target.value
    });
    form.setFieldsValue({
      identity: [e.target.value]
    });
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  handleGenderChange = e => {
    const { form, onChange } = this.props;
    this.setState({
      gender: e.target.value
    });
    form.setFieldsValue({
      gender: [e.target.value]
    });
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  checkNationalityMY = k => {
    const { form } = this.props;
    const nationality = form.getFieldValue(`nationality[${k}]`);
    return nationality === 'MY';
  };

  checkGenderType = k => {
    const { form } = this.props;
    const gender = form.getFieldValue(`gender[${k}]`);
    if (typeof gender == 'string') {
      return gender == 1 ? 'female' : 'male';
    }
    return gender;
  };

  componentDidMount() {
    const { data, form, host, roomType, roomData } = this.props;
    if (data.length > 0) {
      data[0]['key'] = 0;
    }

    let keys = data.map(d => d.key);
    if (keys.length > 0) {
      form.setFieldsValue(
        {
          keys
        },
        this.setState({
          update: true
        })
      );
    } else {
      form.setFieldsValue({
        keys: [0]
      });
      uuid++;
    }
    if (SPECIAL_HOST_IDS.includes(host)) {
      if (roomData.length > 0) {
      }
      const currentRoom = roomData.length > 0 ? roomData.filter(room => room.value == roomType) : undefined;
      const sellingMode =
        currentRoom && currentRoom[0] && currentRoom[0].bookingEngine && currentRoom[0].bookingEngine.sellingMode
          ? currentRoom[0].bookingEngine.sellingMode
          : undefined;
      const sellMode = sellingMode === 'male' || sellingMode === 'female' ? sellingMode : undefined;
      this.setState({
        gender: sellMode
      });
      this.setState({
        sellingMode: sellingMode
      });
    }

    getConstants('countries').then(resCountries => {
      if (resCountries && resCountries.status === 200) {
        let countries = Object.keys(resCountries.data).map(k => {
          return resCountries.data[k];
        });
        if (countries.length > 0) {
          this.setState({
            countryOptions: countries.map(u => {
              return { value: u.iso2, label: u.name };
            })
          });
        }
      } else {
        console.log('Error while retrieving countries');
      }
    });

    getConstants('statesMY').then(resStates => {
      if (resStates && resStates.status === 200) {
        let states = Object.keys(resStates.data).map(k => {
          return resStates.data[k];
        });
        if (states.length > 0) {
          this.setState({
            stateOptions: states.map(state => {
              return { value: state.code, label: state.label };
            })
          });
        }
      } else {
        console.log('Error while retrieving states');
      }
    });

    getConstants('sponsors').then(resSponsors => {
      if (resSponsors && resSponsors.status === 200) {
        let sponsors = Object.keys(resSponsors.data).map(k => {
          return resSponsors.data[k];
        });
        if (sponsors.length > 0) {
          this.setState({
            sponsorOptions: sponsors.map(sponsors => {
              return { value: sponsors.code, label: sponsors.label };
            })
          });
        }
      } else {
        console.log('Error while retrieving sponsors');
      }
    });
  }

  componentDidUpdate(prevProp, prevState) {
    const { update } = this.state;
    const { data, form, host } = this.props;
    if (update) {
      data.forEach(d => {
        let obj = {
          [`firstName[${d.key}]`]: d.firstName,
          [`lastName[${d.key}]`]: d.lastName,
          [`nationality[${d.key}]`]: d.nationality,
          [`contactNo[${d.key}]`]: d.contactNo,
          [`icNo[${d.key}]`]: d.icNo,
          [`email[${d.key}]`]: d.email,
          [`state[${d.key}]`]: d.state
        };
        if (SPECIAL_HOST_IDS.includes(host)) {
          obj[`sponsoredBy[${d.key}]`] = d.sponsoredBy;
          obj[`gender[${d.key}]`] = d.gender;
          obj[`identity[${d.key}]`] = d.identity;
          obj[`studentId[${d.key}]`] = d.studentId;
          obj[`staffId[${d.key}]`] = d.staffId;
        }

        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { disabled, host } = this.props;
    const { sellingMode, gender, identity } = this.state;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => {
      const { countryOptions, stateOptions, sponsorOptions } = this.state;
      return (
        <React.Fragment>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.firstName').d('First Name')} required={true} key={`firstName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`firstName[${k}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.firstName').d("Please input guest's first name.")
                      },
                      {
                        //no digits and special characters regex
                        pattern: /^[^!"#$%&'+/:;<=>?[\\\]^_`{|}~]{1,25}$/u, // Regex for letters in any language
                        message: intl.get('booking_form.guestDetails.alertMessage.firstNameRegex').d('First name must contain only letters.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.firstName').d('First Name')}
                      onBlur={this.handleSubmit}
                      autoComplete="firstName"
                      onBeforeInputCapture={this.handleSubmit}
                    />
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.lastName').d('Last Name')} required={true} key={`lastName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`lastName[${k}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.lastName').d("Please input guest's last name.")
                      },
                      {
                        //no digits and special characters regex
                        pattern: /^[^!"#$%&'+/:;<=>?[\\\]^_`{|}~]{1,25}$/u, // Regex for letters in any language
                        message: intl.get('booking_form.guestDetails.alertMessage.lastNameRegex').d('Last name must contain only letters.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.lastName').d('Last Name')}
                      onBlur={this.handleSubmit}
                      autoComplete="lastName"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.nationality').d('Nationality (Optional)')} key={`nationality${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`nationality[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur']
                  })(
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder={intl.get('booking_form.guestDetails.placeholder.selectCountry').d('Select a Country')}
                      optionFilterProp="children"
                      onBlur={this.handleSubmit}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {countryOptions.map(countries => (
                        <Option value={countries.value} key={countries.value}>
                          {intl.get(`countries.${countries.label.replace(/\./g, '_')}`).d(countries.label)}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.contactNumber').d('Contact Number')} key={`contactNo[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`contactNo[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        whitespace: true,
                        pattern: CONTACT_NUMBER_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.contactNumber').d("Please input guest's contact number.")
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.contactNumber').d('Contact Number')}
                      onBlur={this.handleSubmit}
                      autoComplete="contactNo"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.state').d('State (Optional)')} key={`state[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {this.checkNationalityMY(k)
                    ? getFieldDecorator(`state[${k}]`)(
                        <Select
                          disabled={disabled}
                          showSearch
                          placeholder={intl.get('booking_form.guestDetails.placeholder.selectState').d('Select a State')}
                          optionFilterProp="children"
                          onBlur={this.handleSubmit}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {stateOptions.map(state => (
                            <Option value={state.value} key={state.value}>
                              {intl.get(`statesMY.${state.label.replace(/\./g, '_')}`).d(state.label)}
                            </Option>
                          ))}
                        </Select>
                      )
                    : getFieldDecorator(`state[${k}]`, {
                        rules: [
                          {
                            pattern: /^[a-zA-Z ]*$/,
                            whitespace: true,
                            message: intl.get('booking_form.guestDetails.alertMessage.state').d("Please input guest's state. (Alphabets only)")
                          }
                        ]
                      })(
                        <Input
                          disabled={disabled}
                          placeholder={intl.get('booking_form.guestDetails.placeholder.state').d('State')}
                          onBlur={this.handleSubmit}
                          autoComplete="state"
                        />
                      )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.email').d('E-mail (Optional)')} key={`email[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`email[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        pattern: EMAIL_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.email').d('Please enter a valid email address.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.placeholder.email').d('Email')}
                      type="email"
                      onBlur={this.handleSubmit}
                      autoComplete="emailField"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24}>
              <FormItem label={intl.get('booking_form.guestDetails.ic').d('IC/Passport No, (Optional)')} key={`icNo[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`icNo[${k}]`)(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.placeholder.ic').d('IC/Passport No.')}
                      onBlur={this.handleSubmit}
                      autoComplete="icNo"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          {SPECIAL_HOST_IDS.includes(host) && (
            <Row justify="start" gutter={8}>
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.identity').d('Identity')} key={`identity[${k}]`}>
                  {getFieldDecorator(`identity[${k}]`, {
                    initialValue: identity,
                    rules: [
                      {
                        required: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.identity').d('Please select an identity')
                      }
                    ]
                  })(
                    <Radio.Group buttonStyle="solid" onChange={this.handleIdentityChange}>
                      <Radio.Button value="student">{intl.get('booking_form.guestDetails.student').d('Student')}</Radio.Button>
                      <Radio.Button value="staff">{intl.get('booking_form.guestDetails.staff').d('Staff')}</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              </Col>
              {identity === 'student' && (
                <Col span={24} lg={12}>
                  <FormItem label={intl.get('booking_form.guestDetails.studentId').d('Student ID')} key={`studentId[${k}]`} required={true}>
                    {getFieldDecorator(`studentId[${k}]`, {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: intl.get('booking_form.guestDetails.alertMessage.studentId').d('Please input your student ID')
                        }
                      ]
                    })(
                      <Input
                        placeholder={intl.get('booking_form.guestDetails.placeholder.studentId').d('Student ID')}
                        onBlur={this.handleSubmit}
                        autoComplete="studentId"
                        onBeforeInputCapture={this.handleSubmit}
                      />
                    )}
                  </FormItem>
                </Col>
              )}
              {identity === 'staff' && (
                <Col span={24} lg={12}>
                  <FormItem label={intl.get('booking_form.guestDetails.staffId').d('Staff ID')} key={`staffId[${k}]`} required={true}>
                    {getFieldDecorator(`staffId[${k}]`, {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: intl.get('booking_form.guestDetails.alertMessage.staffId').d('Please input your staff ID')
                        }
                      ]
                    })(
                      <Input
                        placeholder={intl.get('booking_form.guestDetails.placeholder.staffId').d('Staff ID')}
                        onBlur={this.handleSubmit}
                        autoComplete="staffId"
                        onBeforeInputCapture={this.handleSubmit}
                      />
                    )}
                  </FormItem>
                </Col>
              )}
            </Row>
          )}
          {SPECIAL_HOST_IDS.includes(host) && (
            <Row justify="start" gutter={8}>
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.gender').d('Gender')} key={`gender[${k}]`}>
                  {getFieldDecorator(`gender[${k}]`, {
                    initialValue: gender,
                    rules: [
                      {
                        required: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.gender').d('Please select a gender')
                      }
                    ]
                  })(
                    <Radio.Group buttonStyle="solid" onChange={this.handleGenderChange}>
                      <Radio.Button value="male" disabled={sellingMode !== 'normal' && gender === 'female'}>
                        {intl.get('booking_form.guestDetails.genderMale').d('Male')}
                      </Radio.Button>
                      <Radio.Button value="female" disabled={sellingMode !== 'normal' && gender === 'male'}>
                        {intl.get('booking_form.guestDetails.genderFemale').d('Female')}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.sponsoredBy').d('Sponsored By')} key={`sponsoredBy[${k}]`}>
                  <div className="guest-input-control-wrapper">
                    {getFieldDecorator(`sponsoredBy[${k}]`, {
                      validateTrigger: ['onChange', 'onBlur']
                    })(
                      <Select
                        disabled={disabled}
                        showSearch
                        allowClear
                        placeholder={intl.get('booking_form.guestDetails.placeholder.selectSponsor').d('Select a Sponsor')}
                        optionFilterProp="children"
                        onBlur={this.handleSubmit}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {sponsorOptions.map(sponsor => (
                          <Option value={sponsor.value} key={sponsor.value}>
                            {intl.get(`sponsors.${sponsor.label}`).d(sponsor.label)}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </FormItem>
              </Col>
            </Row>
          )}
          <FormItem className="guest-input">
            {keys.length > 1 ? (
              <div className="guest-input-control-wrapper">
                <Popconfirm
                  title={intl.get('booking_form.guestDetails.areYouSureRemoveGuest').d('Are you sure you want to remove this guest?')}
                  onConfirm={() => this.remove(k)}
                  okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                  cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
                >
                  <Button type="danger" disabled={keys.length === 1} style={{ width: '100%' }}>
                    <Icon type="minus" /> {intl.get('booking_form.guestDetails.removeGuest').d('Remove guest')}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </FormItem>
        </React.Fragment>
      );
    });
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <FormItem className="guest-input">
          <Button disabled={disabled} type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addGuest').d('Add guest')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create()(DynamicFieldSet);
export default WrappedDynamicFieldSet;
