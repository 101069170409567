import React, { Component, Fragment } from 'react';
import {
  Avatar,
  Form,
  Select,
  Card,
  Input,
  Row,
  Modal,
  message,
  Tabs,
  Button,
  DatePicker,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Timeline,
  Descriptions,
  Skeleton,
  Rate,
  Tag
} from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withAppContext } from 'context/AppContext';
import { diff } from 'deep-diff';
import CloseButtonReservation from 'components/CloseButton/CloseButtonReservation';

import { getConstants, getBookingStatusConstant, getIntegrationSourcesConstant } from 'utils/apis/constants';
import { getProperties } from 'utils/apis/property';
import { getRateModifierById } from 'utils/apis/rateModifier';
import { getReservationsById, updateReservation, deleteReservation, getUnitListings } from 'utils/apis/reservation';
import { getAirbnbReviews, updateAirbnbReview } from 'utils/apis/integration';
import { postCreateTrxn, putUpdateTrxn, deleteTrxn } from 'utils/apis/transaction';
import {
  CONTACT_NUMBER_REGEX,
  EMAIL_REGEX,
  MIN_INTL_CONTACT_NO_LEN,
  AIRBNB_REVIEW_CATEGORY,
  AIRBNB_GUEST_REVIEW_CATEGORY,
  SPECIAL_HOST_IDS
} from 'utils/constants';
import { constructNumberToArray, cleanNumber, numberWithCommas, generateDisplayFee, guard, checkHasValue } from 'utils/general';
import { buildMultiCalendarUri, buildReservationUri } from 'utils/routes';

import TransactionTab from './components/TransactionTab/TransactionTab';
import FormSelection from '../../components/FormSelection/FormSelection';
import FormRadioButton from '../../components/FormRadioButton/FormRadioButton';
import PdfCardModal from '../../components/ReceiptTemplate/PdfCardModal';
import UploadFiles from '../../components/UploadFiles/UploadFiles';
import './ReservationForm.css';
import intl from 'react-intl-universal';

import { ReactComponent as WhatsAppIcon } from 'images/whatsapp-icon.svg';
import airbnbLogo from 'images/airbnb-logo.png';
import OTALogo from 'components/OTALogo/OTALogo';

// import AddOnTab from './components/AddOnTab/AddOnTab';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const TabPane = Tabs.TabPane;
const latestAvailableUpdateDate = moment().add(729, 'days');

const LinkToReservation = ({ hostId, propertyId, roomTypeId, date }) => (
  <a href={buildMultiCalendarUri({ host: hostId, property: propertyId, roomType: roomTypeId, date })}>
    <Icon type="arrow-right" />
    &nbsp; {intl.get('reservations.reservationDetails.headerLabels.calendar').d('View On Calendar')}
  </a>
);

const LinkToOriginalBooking = ({ resId, resCode }) => (
  <a href={buildReservationUri({ res: resId })}>
    &nbsp; <Icon type="link" /> {`${resCode}`}
  </a>
);

const LinkToDuplicatedBooking = ({ resId, resCode }) => (
  <a href={buildReservationUri({ res: resId })}>
    &nbsp; <Icon type="link" /> {`${resCode}`}
  </a>
);

const constructDiscountLabel = (totalCharges, originalTotalCharges, currency) => {
  const priceDifference = totalCharges - originalTotalCharges;
  if (priceDifference === 0) {
    return ``;
  } else if (priceDifference < 0) {
    return `(-${currency}${numberWithCommas(priceDifference)})`;
  } else {
    return `(+${currency}${numberWithCommas(priceDifference)})`;
  }
};

const convertGender = gender => {
  if (typeof gender === 'number') {
    return gender === 1 ? 'Male' : 'Female';
  }

  return gender;
};

const revertGender = gender => {
  if (typeof gender === 'string') {
    return gender === 'Female' ? 0 : 1;
  }

  return gender;
};

const TransactionDetails = ({
  isLoading,
  reservation,
  checkInDate,
  checkOutDate,
  noOfNight,
  totalCharges,
  originalTotalCharges,
  outstandingBalance,
  depositCollectedForTrxn,
  onDepositChange,
  depositCollectedForTrxnErrMsg,
  onCollectBtnClick,
  onRefundBtnClick,
  onDeductBtnClick,
  pfdCardModalVisibilityToggle,
  showPrice,
  currency = 'RM',
  checkIsAdminReadOnly,
  host
}) => {
  const discountLabel = constructDiscountLabel(totalCharges, originalTotalCharges, currency);
  return (
    <Card
      loading={isLoading}
      title={
        <>
          {intl.get('reservations.paymentDetails.paymentDetails').d('Payment Details')}
          <Button className="downloadPdfButton" type="primary" onClick={pfdCardModalVisibilityToggle}>
            <Icon type="download" />
          </Button>
        </>
      }
    >
      <Row className="bookingFormPayment">
        {intl.get('reservations.paymentDetails.code').d('Confirmation Code')}
        <div className="booking-confirmation-code">
          <OTALogo otaCode={reservation.platform} text={reservation.code} />
        </div>
        {reservation.duplicatedFrom && (
          <>
            {intl.get('reservations.paymentDetails.originalBooking').d('Original Booking')}
            <div>
              <LinkToOriginalBooking resId={reservation.duplicatedFrom._id} resCode={reservation.duplicatedFrom.code} />
            </div>
          </>
        )}
        {!!reservation.duplicatedTo &&
          reservation.duplicatedTo.map(data => (
            <>
              {intl.get('reservations.paymentDetails.extendedBooking').d('Extended Booking')}
              <div>
                <LinkToDuplicatedBooking resId={data._id} resCode={data.code} />
              </div>
            </>
          ))}
      </Row>

      <Row className="bookingFormPayment">
        <Col span={24}>
          {intl.get('booking_form.paymentDetailsLabels.checkIn').d('Check-in')}
          <div className="check-in">{checkInDate}</div>
          <div className="divider" />
        </Col>
        <Col span={24} className="booking-details-right">
          {intl.get('booking_form.paymentDetailsLabels.checkOut').d('Check-out')}
          <div className="check-out">{checkOutDate}</div>
        </Col>
      </Row>
      {showPrice && (
        <Row className="bookingFormPayment">
          <Col span={24} xl={24} className="totalAmount">
            {intl.get('booking_form.paymentDetailsLabels.totalBookingNights', { bookingNights: noOfNight }).d(`Total for ${noOfNight} Night(s)`)}
            <br />
            <div className="booking-number-bottom">{currency + numberWithCommas(parseFloat(totalCharges))}</div>
            {discountLabel && (
              <>
                <div className="original-price">{currency + numberWithCommas(parseFloat(originalTotalCharges))}</div>
                <div className="discount-price">{discountLabel}</div>
              </>
            )}
          </Col>
        </Row>
      )}
      {showPrice && <Row className="booking-form-divider" />}
      {showPrice && (
        <Row className="bookingFormPayment">
          <Col span={24}>{intl.get('booking_form.paymentDetailsLabels.outstandingBalance').d('Outstanding Balance')}</Col>
          <Col span={24} className="booking-number">
            {currency + numberWithCommas(parseFloat(outstandingBalance))}
          </Col>
        </Row>
      )}
      {!reservation.depositCollected > 0 && !reservation.depositRefunded > 0 && (
        <div className="securityDepositSection">
          <Row className="securityDepositItem">
            <div className="securityDepositItem">{intl.get('booking_form.paymentDetailsLabels.securityDeposit').d('Security Deposit')}</div>
            <Input
              addonBefore={currency}
              placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
              type="number"
              step="any"
              min={0}
              value={depositCollectedForTrxn}
              name="depositCollected"
              defaultValue={0}
              onChange={onDepositChange}
            />
            {depositCollectedForTrxnErrMsg && (
              <div className="errorMsg">
                `$
                {depositCollectedForTrxnErrMsg}`
              </div>
            )}
          </Row>
          <Row>
            <Button
              type="primary"
              size="large"
              style={{ width: '70%' }}
              onClick={onCollectBtnClick}
              disabled={!Number(depositCollectedForTrxn) > 0 || checkIsAdminReadOnly()}
            >
              {intl.get('booking_form.paymentDetailsLabels.collect').d('Collect')}
            </Button>
          </Row>
        </div>
      )}
      {reservation.depositCollected && !reservation.depositRefunded && (
        <div className="securityDepositSection">
          <Row>
            <Col span={24} className="securityDepositItem">
              <div>Security Deposit Collected</div>
              <div className="booking-number">
                {currency +
                  numberWithCommas(
                    parseFloat(
                      reservation.deductedDeposits ? reservation.depositCollected - reservation.deductedDeposits : reservation.depositCollected || 0
                    )
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            {SPECIAL_HOST_IDS.includes(host) && !reservation.deductedDeposits ? (
              <Button type="primary" size="large" style={{ width: '70%' }} disabled={checkIsAdminReadOnly()} onClick={onDeductBtnClick}>
                {intl.get('booking_form.paymentDetailsLabels.deductDeposit').d('Deduct Deposit')}
              </Button>
            ) : !reservation.deductedDeposits ? (
              <Button type="primary" size="large" style={{ width: '70%' }} disabled={checkIsAdminReadOnly()} onClick={onRefundBtnClick}>
                {intl.get('booking_form.paymentDetailsLabels.refund').d('Refund')}
              </Button>
            ) : null}
          </Row>
        </div>
      )}
      {reservation.depositRefunded && (
        <div className="securityDepositSection">
          <Row>
            <Col span={24} className="securityDepositItem">
              <div>{intl.get('booking_form.paymentDetailsLabels.securityDepositRefunded').d('Security Deposit Refunded')}</div>
              <div className="booking-number">{'RM' + numberWithCommas(parseFloat(reservation.depositRefunded || 0))}</div>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

const ActionButtons = ({ shouldShowDeleteButton, isSaveButtonLoading, isDeleteButtonLoading, onDelete, checkIsAdminReadOnly, className }) => {
  return (
    <FormItem className={className}>
      <Fragment>
        <div className="saveButton">
          <Button type="primary" htmlType="submit" disabled={checkIsAdminReadOnly()} loading={isSaveButtonLoading}>
            {intl.get('reservations.headerLabels.save').d('Save')}
          </Button>
          {shouldShowDeleteButton && (
            <Button type="danger" onClick={onDelete} disabled={checkIsAdminReadOnly()} className="sp-button-margin" loading={isDeleteButtonLoading}>
              {intl.get('reservations.headerLabels.delete').d('Delete')}
            </Button>
          )}
        </div>
      </Fragment>
    </FormItem>
  );
};

const getDisabledDate = currentDate => {
  const isDisable = currentDate > latestAvailableUpdateDate;

  return isDisable;
};

class ReservationForm extends Component {
  constructor() {
    super();
    this.state = {
      depositCollectedForTrxn: 0,
      depositCollectedForTrxnErrMsg: '',
      reservationDetails: {},
      userProfileId: '',
      unitViews: [],
      countryOptions: [],
      stateOptions: [],
      sponsorOptions: [],
      platformOptions: [],
      vehicleColorOptions: [],
      fullUnitOptions: [],
      propertyOptions: [],
      roomTypeOptions: [],
      unitOptions: [],
      rateModifierOptions: [],
      bookingTypeOptions: [],
      unitViewOptions: [],
      bookingStatusOptions: [],
      update: false,
      isLoading: true,
      pfdCardModalVisible: false,
      activityLog: [],
      bookingStatus: [],
      currentRoomType: '',

      defaultOccupantKeys: [],
      defaultVehicleKeys: [],
      defaultItemsProvidedKeys: [],
      defaultStartDate: '',
      defaultEndDate: '',
      defaultHost: '',
      defaultProperty: '',
      defaultRoomType: '',
      defaultUnit: '',
      defaultRateModifier: '',
      defaultNoPax: '',
      defaultBookingType: '',
      defaultSource: '',
      defaultBookingStatus: '',
      defaultRemarks: '',
      defaultOccupants: {},
      defaultVehicles: {},
      defaultItemsProvided: {},
      defaultPriceDetails: {},
      defaultAddOns: {},
      addOnObj: {},
      defaultEmergencyContactName: '',
      defaultEmergencyContactNo: '',
      isOTAReservation: false,
      isNonProfitBookingType: false,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      permissions: [],
      permissionConstants: {},
      isAdmin: false,
      currency: 'RM',
      timezone: '',

      airbnbReviews: [],
      cleanlinessVisibility: false,
      respectVisibility: false,
      communicationVisibility: false,
      toggleReply: false,
      attachments: []
    };
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.handleOnStartCalendarChange = this.handleOnStartCalendarChange.bind(this);
    this.handleOnRoomTypeChange = this.handleOnRoomTypeChange.bind(this);
    this.handleOnPropertyChange = this.handleOnPropertyChange.bind(this);
    this.handleOnEndCalendarChange = this.handleOnEndCalendarChange.bind(this);
    this.checkIsDateMatching = this.checkIsDateMatching.bind(this);
    this.authorizedItem = this.authorizedItem.bind(this);
  }

  vehicleKeyUUID = 0;
  guestKeyUUID = 0;
  itemsProvidedKeyUUID = 0;
  addOnsKeyUUID = 0;

  componentDidMount = async () => {
    getConstants('roomViews').then(resUnitViews => {
      if (resUnitViews && resUnitViews.status === 200) {
        let unitViews = Object.keys(resUnitViews.data).map(k => {
          return resUnitViews.data[k];
        });
        if (unitViews.length > 0) {
          this.setState({
            unitViewOptions: unitViews.map(u => {
              return { value: u.code, label: u.label };
            })
          });
        }
      } else {
        message.error('Error while retrieving unit views');
      }
    });

    getConstants('permissions').then(res => {
      const authObj = JSON.parse(localStorage.getItem('auth'));

      if (res && res.status === 200) {
        this.setState({
          permissionConstants: res.data,
          permissions: authObj && authObj.user.roles.length > 0 ? authObj.user.roles[0].permissions : [],
          isAdmin: authObj.user.isAdmin
        });
      } else {
        console.log('Error getting permissions constants.');
      }
    });

    getConstants('bookingTypes').then(resBookingTypes => {
      if (resBookingTypes && resBookingTypes.status === 200) {
        let bookingTypes = Object.keys(resBookingTypes.data).map(k => resBookingTypes.data[k]);
        if (bookingTypes.length > 0) {
          this.setState({
            bookingTypeOptions: bookingTypes.map(u => {
              return { value: u.code, label: u.label, hasPrice: u.hasPrice };
            })
          });
        }
      } else {
        message.error('Error while retrieving booking types');
      }
    });

    getConstants('countries').then(resCountries => {
      if (resCountries && resCountries.status === 200) {
        let countries = Object.keys(resCountries.data).map(k => {
          return resCountries.data[k];
        });
        if (countries.length > 0) {
          this.setState({
            countryOptions: countries.map(u => {
              return { value: u.iso2, label: u.name };
            })
          });
        }
      } else {
        message.error('Error while retrieving countries');
      }
    });

    getConstants('statesMY').then(resStates => {
      if (resStates && resStates.status === 200) {
        let states = Object.keys(resStates.data).map(k => {
          return resStates.data[k];
        });
        if (states.length > 0) {
          this.setState({
            stateOptions: states.map(state => {
              return { value: state.code, label: state.label };
            })
          });
        }
      } else {
        message.error('Error while retrieving states');
      }
    });

    getConstants('sponsors').then(resSponsors => {
      if (resSponsors && resSponsors.status === 200) {
        let sponsors = Object.keys(resSponsors.data).map(k => {
          return resSponsors.data[k];
        });
        if (sponsors.length > 0) {
          this.setState({
            sponsorOptions: sponsors.map(sponsors => {
              return { value: sponsors.code, label: sponsors.label };
            })
          });
        }
      } else {
        console.log('Error while retrieving sponsors');
      }
    });

    const platformOptions = await getIntegrationSourcesConstant()
      .then(platformSources => {
        if (Object.keys(platformSources).length > 0) {
          return Object.values(platformSources).map(u => {
            return { value: u.code, label: u.label, isIntegrated: u.isIntegrated };
          });
        }
      })
      .catch(() => {
        message.error('Error while retrieving unit views');
      });

    if (this.props.match.params.id) {
      getReservationsById(this.props.match.params.id, { isWithTransaction: true })
        .then(async res => {
          if (res && res.status === 200) {
            res.data.activityLog.forEach((v, idx, array) => {
              let differences = [];
              if (v.oldData) {
                let dataDiff = diff(v.oldData, v.newData);
                differences = differences.concat(dataDiff);
              }
              if (idx !== 0) {
                let createdByDiff = diff(array[idx - 1].createdBy, v.createdBy);
                differences = differences.concat(createdByDiff);
              }
              v.differences = differences;
              // console.log(differences);
            });
            this.setState({
              reservationDetails: res.data,
              userProfileId: res.data.guestDetails.userProfile ? res.data.guestDetails.userProfile._id : '',
              isLoading: false,
              activityLog: res.data.activityLog,
              currency: res.data.currency,
              timezone: res.data.timezone,
              currentRoomType: res.data.unit.roomType._id
            });

            if (res.data.platform === 'airbnb' && res.data.sourceId) {
              getAirbnbReviews(res.data.sourceId).then(res => {
                if (res && res.status === 200 && res.data.reviews) {
                  this.setState({
                    airbnbReviews: res.data.reviews
                  });
                }
              });
            }

            if (res.data.rateModifier) {
              getRateModifierById(typeof res.data.rateModifier === 'object' ? res.data.rateModifier._id : res.data.rateModifier).then(
                rateModifier => {
                  const { form } = this.props;
                  const { _id, name, type, amount, isPositive } = rateModifier;
                  this.setState({
                    rateModifierOptions: [{ value: _id, label: name, type, amount, isPositive }]
                  });
                  form.setFieldsValue({
                    rateModifier: _id || undefined
                  });
                }
              );
            }
            let occupants = {};
            if (res.data.guestDetails.userProfile) {
              for (let i = 0; i < res.data.guestDetails.occupants.length + 1; i++) {
                let curOccupant;
                if (i === 0) {
                  curOccupant = res.data.guestDetails.userProfile;
                  curOccupant.contactNo = curOccupant.contactNos[0];
                  curOccupant.email = curOccupant.emails[0];
                  curOccupant.icNo = curOccupant.identificationNo;
                  curOccupant.gender = convertGender(curOccupant.gender);
                  curOccupant.studentId = curOccupant.studentId ? curOccupant.studentId : undefined;
                  curOccupant.staffId = curOccupant.staffId ? curOccupant.staffId : undefined;
                  curOccupant.sponsoredBy = curOccupant.sponsoredBy ? curOccupant.sponsoredBy : undefined;
                } else {
                  curOccupant = res.data.guestDetails.occupants[i - 1];
                }
                occupants[i] = {
                  firstName: curOccupant.firstName,
                  lastName: curOccupant.lastName,
                  nationality: curOccupant.nationality,
                  contactNo: curOccupant.contactNo,
                  state: curOccupant.state,
                  email: curOccupant.email,
                  icNo: curOccupant.icNo,
                  gender: convertGender(curOccupant.gender),
                  studentId: curOccupant.studentId ? curOccupant.studentId : undefined,
                  staffId: curOccupant.staffId ? curOccupant.staffId : undefined,
                  sponsoredBy: curOccupant.sponsoredBy ? curOccupant.sponsoredBy : undefined
                };
                // console.log(curOccupant);
              }
            }
            // console.log(occupants);
            let vehicles = {};
            const vehicleDetailsLength = res.data.guestDetails.vehicleDetails ? res.data.guestDetails.vehicleDetails.length : 0;
            for (let i = 0; i < res.data.guestDetails.vehicleDetails.length; i++) {
              let curVehicle;

              curVehicle = res.data.guestDetails.vehicleDetails[i];

              vehicles[i] = {
                vehicleColor: curVehicle.vehicleColor,
                vehicleModel: curVehicle.vehicleModel,
                vehicleNo: curVehicle.vehicleNo,
                vehicleType: curVehicle.vehicleType
              };
            }

            let itemsProvided = {};
            const itemsProvidedLength = res.data.guestDetails.itemsProvided ? res.data.guestDetails.itemsProvided.length : 0;
            for (let i = 0; i < itemsProvidedLength; i++) {
              let curItemsProvided = res.data.guestDetails.itemsProvided[i];

              itemsProvided[i] = {
                item: curItemsProvided.item,
                label: curItemsProvided.label
              };
            }

            // const addOnTransactionData = this.state.reservationDetails.transactions.filter(transaction => transaction.type === 'addon');
            let addOns = [];

            const addOnsLength = res.data.unit.roomType.property.addOns ? res.data.unit.roomType.property.addOns.length : 0;
            for (let i = 0; i < addOnsLength; i++) {
              let curAddOns = res.data.unit.roomType.property.addOns[i];
              addOns[i] = {
                activity: curAddOns.activity,
                amount: curAddOns.amount,
                pax: 0,
                _id: ''
              };
            }
            this.vehicleKeyUUID = vehicleDetailsLength;
            this.guestKeyUUID = res.data.guestDetails.occupants.length + 1;
            // this.guestKeyUUID = res.data.guestDetails.occupants.length;
            this.itemsProvidedKeyUUID = itemsProvidedLength;
            this.addOnsKeyUUID = addOnsLength;

            const defaultBookingType = res.data.bookingType;
            const defaultBookingStatus = res.data.bookingStatus;

            const defaultSource = res.data.platform;
            const isOTAReservation = !!platformOptions.find(platformOption => platformOption.isIntegrated && platformOption.value === defaultSource);

            this.setState({
              defaultOccupantKeys: constructNumberToArray(this.guestKeyUUID),
              defaultVehicleKeys: constructNumberToArray(this.vehicleKeyUUID),
              defaultItemsProvidedKeys: constructNumberToArray(this.itemsProvidedKeyUUID),
              defaultStartDate: res.data.startDate,
              defaultEndDate: res.data.endDate,
              defaultHost: res.data.unit.roomType.property.host._id,
              defaultProperty: res.data.unit.roomType.property._id,
              defaultRoomType: res.data.unit.roomType._id,
              defaultUnit: res.data.unit._id,
              defaultRateModifier: typeof res.data.rateModifier === 'object' ? res.data.rateModifier._id : res.data.rateModifier, // to check whether booking website uses promotion
              defaultNoPax: res.data.guestDetails.numberOfPax,
              defaultBookingType: defaultBookingType,
              defaultSource: defaultSource,
              defaultRemarks: res.data.remarks,
              defaultOccupants: occupants,
              defaultVehicles: vehicles,
              defaultItemsProvided: itemsProvided,
              defaultPriceDetails: res.data.priceDetails,
              defaultAddOns: addOns,
              defaultEmergencyContactName: res.data.guestDetails.emergency ? res.data.guestDetails.emergency.emergencyName : '',
              defaultEmergencyContactNo: res.data.guestDetails.emergency ? res.data.guestDetails.emergency.emergencyContact : '',
              platformOptions: platformOptions,
              isOTAReservation: isOTAReservation,
              attachments: res.data.attachments
            });
            this.setBookingStatus(defaultBookingType, defaultBookingStatus);
            this.refreshUnit(res.data.unit.roomType.property._id, res.data.startDate, res.data.endDate);
            this.refreshRatesAndUnits(res.data.unit.roomType._id, true);
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    }

    getConstants('vehicleColors').then(resVehicleColors => {
      if (resVehicleColors && resVehicleColors.status === 200) {
        let vehicleColorOptions = Object.keys(resVehicleColors.data).map(k => {
          return resVehicleColors.data[k];
        });
        if (vehicleColorOptions.length > 0) {
          this.setState({
            vehicleColorOptions
          });
        }
      } else {
        message.error('Error while retrieving unit views');
      }
    });

    getProperties()
      .then(properties => {
        if (properties.length > 0) {
          this.setState({
            propertyOptions: properties.map(u => {
              return { value: u._id, label: u.name, host: u.host };
            })
          });
        }
      })
      .catch(err => {
        console.error(err);
        message.error(err);
        return [{ label: '', value: '' }];
      });

    getBookingStatusConstant().then(bookingStatus => {
      if (bookingStatus) {
        this.setState({
          bookingStatusOptions: Object.values(bookingStatus)
            .filter(bs => bs.showDuringUpdate && (!bs.onDayOnly || (this.checkIsDateMatching() && this.checkIsCommercialReservation())))
            .map(bs => {
              return (
                <Option key={bs.code} value={bs.code}>
                  {intl.get(`reservations.reservationDetails.bookingStatus.${bs.label}`)}
                </Option>
              );
            }),
          bookingStatus: Object.values(bookingStatus).map(bs => {
            return { code: bs.code, label: bs.label };
          })
        });
      }
    });
  };

  setBookingStatus = (defaultBookingType, defaultBookingStatus) => {
    const isNonProfitBookingType = !!defaultBookingType && !this.checkIsCommercialReservation(defaultBookingType);
    const newBookingStatus = isNonProfitBookingType ? 'Confirmed' : defaultBookingStatus;

    this.setState({ defaultBookingStatus: newBookingStatus, isNonProfitBookingType });
  };

  pfdCardModalVisibilityToggle = () => {
    this.setState(prevState => {
      return { pfdCardModalVisible: !prevState.pfdCardModalVisible };
    });
  };

  ratingCategoryVisibilityToggle = (e, type) => {
    this.setState(prevState => {
      if (e > 0) {
        if (type === 'cleanliness') {
          return { cleanlinessVisibility: true };
        } else if (type === 'respect') {
          return { respectVisibility: true };
        } else if (type === 'communication') {
          return { communicationVisibility: true };
        }
      }
    });
  };

  toggleReplyReview = e => {
    this.setState({ toggleReply: true });
  };

  refreshUnit = (currentPropertyId, startDate, endDate) => {
    const { form } = this.props;
    getUnitListings({
      propertyId: currentPropertyId,
      startDate,
      endDate,
      totalOccupants: 1,
      isBookingLogic: true
    }).then(resUnits => {
      if (resUnits && resUnits.status === 200) {
        let units = resUnits.data;
        if (units.length > 0) {
          const fullUnitOptions = units.map(u => {
            return {
              value: u._id,
              label: u.name,
              roomType: u.roomType._id,
              isAvailable: checkHasValue(u.isAvailable)
                ? u.isAvailable || u.reservations.map(reserv => reserv._id.toString()).includes(this.props.match.params.id)
                : u.reservations.length === 0 || u.reservations.map(reserv => reserv._id.toString()).includes(this.props.match.params.id)
            };
          });
          let roomTypeOptions = units
            .filter((unit, i, arr) => {
              return unit.roomType && unit.roomType._id && arr.map(unit => unit.roomType._id.toString()).indexOf(unit.roomType._id.toString()) === i;
            })
            .map(k => {
              const unitRoomTypeJSON = fullUnitOptions.reduce((hash, obj) => {
                if (obj.roomType === undefined) return hash;
                return Object.assign(hash, { [obj.roomType]: (hash[obj.roomType] || []).concat(obj) });
              }, {});

              return { value: k.roomType._id, label: k.roomType.name, isAvailable: unitRoomTypeJSON[k.roomType._id].some(u => u.isAvailable) };
            });
          this.setState({
            fullUnitOptions,
            roomTypeOptions
          });

          let currentRoomType = form.getFieldValue('roomType');

          if (currentRoomType === null) {
            currentRoomType =
              roomTypeOptions.filter(roomTypeOption => roomTypeOption.isAvailable).length > 0
                ? roomTypeOptions.filter(roomTypeOption => roomTypeOption.isAvailable)[0].value
                : null;
            form.setFieldsValue({
              roomType: currentRoomType
            });
          }

          if (
            !this.state.currentRoomType ||
            !roomTypeOptions
              .filter(roomTypeOption => roomTypeOption.isAvailable)
              .map(roomTypeOption => roomTypeOption.value)
              .includes(this.state.currentRoomType)
          ) {
            currentRoomType =
              roomTypeOptions.filter(roomTypeOption => roomTypeOption.isAvailable).length > 0
                ? roomTypeOptions.filter(roomTypeOption => roomTypeOption.isAvailable)[0].value
                : null;

            console.log('this.state.currentRoomType', this.state.currentRoomType);
            console.log('currentRoomType', currentRoomType);
            console.log('roomTypeOptions', roomTypeOptions);

            form.setFieldsValue({
              roomType: currentRoomType
            });
          }

          let unitOptions = fullUnitOptions.filter(u => u.roomType === currentRoomType);
          this.setState({
            unitOptions,
            isLoading: false
          });

          const currentUnit = form.getFieldValue('unit');
          if (
            !currentUnit ||
            !unitOptions
              .filter(unitOption => unitOption.isAvailable)
              .map(unitOption => unitOption.value)
              .includes(currentUnit)
          ) {
            this.setState({
              isLoading: false
            });
            form.setFieldsValue({
              unit:
                unitOptions.filter(unitOption => unitOption.isAvailable).length > 0
                  ? unitOptions.filter(unitOption => unitOption.isAvailable)[0].value
                  : null
            });
          }
        }
      }
    });
  };

  refreshRatesAndUnits = (roomTypeId, isFirstTimeLoading = false) => {
    const { form } = this.props;
    const unitOptions = this.state.fullUnitOptions.filter(unit => unit.roomType.toString() === roomTypeId.toString());
    const availableUnit = unitOptions.find(unitOption => unitOption.isAvailable);
    if (!isFirstTimeLoading) {
      if (availableUnit) {
        form.setFieldsValue({
          unit: availableUnit.value
        });
      } else {
        form.setFieldsValue({
          unit: ''
        });
      }
    }
    this.setState({
      unitOptions
    });
  };

  removeGuest = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('occupantKeys');
    // We need at least one guest
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      occupantKeys: keys.filter(key => key !== k)
    });
  };

  removeVehicle = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('vehicleKeys');
    form.setFieldsValue({
      vehicleKeys: keys.filter(key => key !== k)
    });
  };

  removeItemsProvided = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('itemsProvidedKeys');
    form.setFieldsValue({
      itemsProvidedKeys: keys.filter(key => key !== k)
    });
  };

  addGuest = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('occupantKeys');
    let nextKey = ++this.guestKeyUUID;
    const newDefaultOccupants = {
      ...this.state.defaultOccupants,
      [nextKey]: {
        firstName: '',
        lastName: '',
        nationality: '',
        contactNo: '',
        state: '',
        email: '',
        icNo: '',
        gender: '',
        studentId: '',
        staffId: '',
        sponsoredBy: ''
      }
    };

    keys.push(nextKey);
    form.setFieldsValue({
      occupantKeys: keys
    });

    this.setState({
      defaultOccupants: newDefaultOccupants
    });
  };

  addVehicle = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('vehicleKeys');
    let nextKey = ++this.vehicleKeyUUID;

    keys.push(nextKey);
    form.setFieldsValue({
      vehicleKeys: keys
    });
  };

  addItemsProvided = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('itemsProvidedKeys');
    let nextKey = ++this.itemsProvidedKeyUUID;

    keys.push(nextKey);
    form.setFieldsValue({
      itemsProvidedKeys: keys
    });
  };

  checkIsCommercialReservation = bookingType => {
    const { form } = this.props;
    const { bookingTypeOptions, defaultBookingType } = this.state;
    const currentBookingType = bookingType || form.getFieldValue('bookingType') || defaultBookingType;

    const commercialBookingType = bookingTypeOptions.filter(bookingType => bookingType.hasPrice).map(bookingType => String(bookingType.value));
    return commercialBookingType.includes(currentBookingType.toString());
  };

  handleOnInputChange(e) {
    const target = e.target;
    let value = target.value;
    this.setState({
      [target.name]: value
    });
  }

  getCorrectCheckInAndOutDates = (originalCheckInDate, originalCheckOutDate, form, isCheckInDate) => {
    let checkInDate = moment(originalCheckInDate);
    let checkOutDate = moment(originalCheckOutDate);
    if (checkInDate >= checkOutDate) {
      if (isCheckInDate) {
        checkOutDate = checkInDate.add(1, 'd');
        form.setFieldsValue({
          checkOutDate: checkOutDate
        });
      } else {
        checkInDate = checkOutDate.add(-1, 'd');
        form.setFieldsValue({
          checkInDate: checkInDate
        });
      }
    }

    return [checkInDate.format('YYYY-MM-DD'), checkOutDate.format('YYYY-MM-DD')];
  };

  getAddOnArray() {
    let { addOnObj } = this.state;
    // console.log(750, addOnObj);
    if (addOnObj) {
      return Object.keys(addOnObj).map(key => {
        return {
          activity: key,
          amount: parseFloat(addOnObj[key].amount || 0),
          pax: addOnObj[key].pax
        };
      });
    } else {
      return [];
    }
  }

  handleOnStartCalendarChange(date, dateString) {
    const { form } = this.props;
    let originalCheckOutDate = form.getFieldValue('checkOutDate') ? form.getFieldValue('checkOutDate').format('YYYY-MM-DD') : undefined;
    const [checkInDate, checkOutDate] = this.getCorrectCheckInAndOutDates(dateString, originalCheckOutDate, form, true);

    this.refreshUnit(form.getFieldValue('property'), moment(dateString).format('YYYY-MM-DD'), checkOutDate);
  }

  handleOnEndCalendarChange(dateString) {
    const { form } = this.props;
    let originalCheckInDate = form.getFieldValue('checkInDate') ? form.getFieldValue('checkInDate').format('YYYY-MM-DD') : undefined;
    const [checkInDate, checkOutDate] = this.getCorrectCheckInAndOutDates(originalCheckInDate, dateString, form, false);

    this.refreshUnit(form.getFieldValue('property'), checkInDate, moment(dateString).format('YYYY-MM-DD'));
  }

  handleOnPropertyChange(value) {
    const { form } = this.props;
    // console.log(form.getFieldValue('startDate'), form.getFieldValue('endDate'));
    // this.refreshUnit(value, form.getFieldValue('startDate'), form.getFieldValue('endDate'));
    this.refreshUnit(value, form.getFieldValue('checkInDate').format('YYYY-MM-DD'), form.getFieldValue('checkOutDate').format('YYYY-MM-DD'));
  }

  handleOnRoomTypeChange(value) {
    this.refreshRatesAndUnits(value);
    this.setState({
      currentRoomType: value
    });
  }

  handleOnVehicleSubmit = i => e => {
    this.props.form.validateFields({ first: true }, (err, values) => {});
  };

  handleOnitemsProvidedSubmit = () => e => {
    this.props.form.validateFields({ first: true }, (err, values) => {});
  };

  handleOnGuestSubmit = e => {
    this.props.form.validateFields({ first: true }, (err, values) => {});
  };

  checkIsDateMatching() {
    const { getFieldValue } = this.props.form;
    const today = moment(new Date());
    return getFieldValue('checkInDate') && today.format('YYYY-MM-DD') === getFieldValue('checkInDate').format('YYYY-MM-DD');
  }

  authorizedItem = keys => {
    const { isAdmin, permissions } = this.state;
    if (isAdmin) {
      return true;
    }

    return keys.filter(key => permissions.includes(key)).length > 0 ? true : false;
  };

  handleOnUploadFinish = e => {
    const { form } = this.props;
    this.setState(prevState => {
      const uploadedFile = {
        name: e.file.name,
        link: e.fileUrl
      };
      const newAttachments = prevState.attachments ? [...prevState.attachments, uploadedFile] : [uploadedFile];
      form.setFieldsValue({
        attachments: newAttachments
      });
      return { attachments: newAttachments };
    });
  };

  handleOnFileDelete = index => e => {
    const { form } = this.props;
    e.preventDefault();
    this.setState(prevState => {
      const { attachments } = prevState;
      attachments.splice(index, 1);
      form.setFieldsValue({
        attachments
      });
      return {
        attachments
      };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const state = this;

    const {
      history,
      match: {
        params: { id }
      }
    } = this.props;
    const { userProfileId } = this.state;

    if (this.checkIsCommercialReservation()) {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const occupants = [];
          for (let i = 0; i < values.occupantKeys.length; i++) {
            const occupantKey = values.occupantKeys[i];
            occupants.push({
              firstName: values.firstName[occupantKey],
              lastName: values.lastName[occupantKey],
              nationality: values.nationality[occupantKey],
              contactNo: values.contactNo[occupantKey],
              state: values.state[occupantKey],
              icNo: values.icNo && values.icNo[occupantKey] ? values.icNo[occupantKey] : '',
              email: values.email && values.email[occupantKey] ? values.email[occupantKey] : '',
              gender: values.gender && values.gender[occupantKey] ? revertGender(values.gender[occupantKey]) : '',
              studentId: values.studentId && values.studentId[occupantKey] ? values.studentId[occupantKey] : '',
              staffId: values.staffId && values.staffId[occupantKey] ? values.staffId[occupantKey] : '',
              sponsoredBy: values.sponsoredBy && values.sponsoredBy[occupantKey] ? values.sponsoredBy[occupantKey] : ''
            });
          }
          const vehicleDetails = [];
          for (let i = 0; i < values.vehicleKeys.length; i++) {
            const vehicleKey = values.vehicleKeys[i];
            vehicleDetails.push({
              vehicleType: values.vehicleType[vehicleKey] ? values.vehicleType[vehicleKey] : '',
              vehicleModel: values.vehicleModel[vehicleKey] ? values.vehicleModel[vehicleKey] : '',
              vehicleNo: values.vehicleNo[vehicleKey] ? values.vehicleNo[vehicleKey] : '',
              vehicleColor: values.vehicleColor[vehicleKey] ? values.vehicleColor[vehicleKey] : ''
            });
          }
          const items = [];
          for (let i = 0; i < values.itemsProvidedKeys.length; i++) {
            const itemsProvidedKey = values.itemsProvidedKeys[i];
            items.push({
              item: values.item[itemsProvidedKey] ? values.item[itemsProvidedKey] : '',
              label: values.label[itemsProvidedKey] ? values.label[itemsProvidedKey] : ''
            });
          }

          const formattedPayload = {
            startDate: values.checkInDate.format('YYYY-MM-DD'),
            endDate: values.checkOutDate.format('YYYY-MM-DD'),
            remarks: values.remarks,
            bookingStatus: values.bookingStatus,
            bookingType: values.bookingType,
            unit: values.unit,
            guestDetails: {
              ...this.state.guestDetails,
              userProfile: userProfileId,
              numberOfPax: values.noPax,
              vehicleDetails,
              occupants,
              emergency: {
                emergencyContact: values.emergencyContactNo,
                emergencyName: values.emergencyContactName
              },
              itemsProvided: items
            },
            attachments: values.attachments
          };

          Modal.confirm({
            title: intl.get('reservations.reservationDetails.placeholder.saveTitle').d('Are you sure you want to overwrite existing data?'),
            content: intl
              .get('reservations.reservationDetails.placeholder.saveContent')
              .d(
                'You will not be able to undo this action, but you may update it again. \n\nPlease note that changing dates for any reservation from OTAs (Airbnb, Booking.com, etc) will only reflect in HostPlatform.'
              ),
            onOk() {
              state.setState({ isSaveButtonLoading: true });
              updateReservation(id, formattedPayload)
                .then(res => {
                  if (res.status === 200) {
                    history.push('/reservation');
                    message.success(intl.get('reservations.reservationDetails.placeholder.saveMsg1').d('Reservation updated!'));
                  } else if (res.status === 403) {
                    state.setState({ isSaveButtonLoading: false });
                    message.warning(
                      intl.get('reservations.reservationDetails.placeholder.saveMsg2').d('Reservation cannot be updated due to dependency.')
                    );
                  } else if (res.status === 400 && res.data.code === '30001') {
                    state.setState({ isSaveButtonLoading: false });
                    message.error(
                      intl
                        .get('reservations.reservationDetails.placeholder.saveMsg3')
                        .d('Reservation is not updated due to clashing with other reservation.')
                    );
                  } else {
                    state.setState({ isSaveButtonLoading: false });
                    message.error(
                      intl.get('reservations.reservationDetails.placeholder.saveMsg4').d('Something went wrong and reservation is not updated.')
                    );
                  }
                })
                .catch(ex => {
                  console.log(ex);
                });
            },
            onCancel() {}
          });
        } else {
          let selectedTab = '';
          if (err.checkInDate || err.checkOutDate) {
            selectedTab = 'reservationDetails';
          } else {
            selectedTab = 'guestDetails';
          }
          history.push(`${history.location.pathname}?selectedTab=${selectedTab}`);
        }
      });
    } else {
      this.props.form.validateFieldsAndScroll((err, values) => {
        let formattedPayload;
        if ([5, 6].includes(values.bookingType)) {
          formattedPayload = {
            startDate: values.checkInDate.format('YYYY-MM-DD'),
            endDate: values.checkOutDate.format('YYYY-MM-DD'),
            remarks: values.remarks,
            guestDetails: {
              numberOfPax: this.state.numberOfPax
            }
          };
        } else {
          formattedPayload = {
            startDate: values.checkInDate.format('YYYY-MM-DD'),
            endDate: values.checkOutDate.format('YYYY-MM-DD'),
            remarks: values.remarks,
            bookingStatus: values.bookingStatus,
            guestDetails: {
              numberOfPax: this.state.numberOfPax
            }
          };
        }

        if (!err) {
          Modal.confirm({
            title: intl.get('reservations.reservationDetails.placeholder.saveTitle').d('Are you sure you want to overwrite existing data?'),
            content: intl
              .get('reservations.reservationDetails.placeholder.saveContent')
              .d(
                'You will not be able to undo this action, but you may update it again. \n\nPlease note that changing dates for any reservation from OTAs (Airbnb, Booking.com, etc) will only reflect in HostPlatform.'
              ),
            onOk() {
              state.setState({ isSaveButtonLoading: true });
              updateReservation(id, formattedPayload)
                .then(res => {
                  if (res.status === 200) {
                    history.push('/reservation');
                    message.success(intl.get('reservations.reservationDetails.placeholder.saveMsg1').d('Reservation updated!'));
                  } else if (res.status === 403) {
                    state.setState({ isSaveButtonLoading: false });
                    message.warning(
                      intl.get('reservations.reservationDetails.placeholder.saveMsg2').d('Reservation cannot be updated due to dependency.')
                    );
                  } else {
                    state.setState({ isSaveButtonLoading: false });
                    message.error(
                      intl
                        .get('reservations.reservationDetails.placeholder.saveMsg5')
                        .d('This unit is unavailable on selected dates. Please select new dates.')
                    );
                  }
                })
                .catch(ex => {
                  console.log(ex);
                });
            },
            onCancel() {}
          });
        }
      });
    }
  };

  handleOnAirbnbReviewSubmit = event => {
    const { form } = this.props;
    const { reservationDetails, airbnbReviews, toggleReply } = this.state;

    event.stopPropagation();
    event.preventDefault();
    const hostToGuestReviewId = airbnbReviews
      .map(review => {
        return { id: review.id, role: review.reviewer_role };
      })
      .filter(review => review.role === 'host');
    const guestToHostReviewId = airbnbReviews
      .map(review => {
        return { id: review.id, role: review.reviewer_role };
      })
      .filter(review => review.role === 'guest');

    form.validateFieldsAndScroll((err, values) => {
      let formattedPayload = {};
      if (toggleReply) {
        formattedPayload = {
          reviewee_response: values.reply,
          unitId: reservationDetails.unit._id
        };
      } else {
        formattedPayload = {
          is_reviewee_recommended: values.recommend,
          category_ratings: [
            {
              category: 'cleanliness',
              rating: values.cleanlinessRating,
              review_category_tags: values.cleanliness,
              comment: values.cleanlinessComment
            },
            {
              category: 'respect_house_rules',
              rating: values.respectRating,
              review_category_tags: values.respect,
              comment: values.respectComment
            },
            {
              category: 'communication',
              rating: values.communicationRating,
              review_category_tags: values.communication,
              comment: values.communicationComment
            }
          ],
          public_review: values.public,
          private_feedback: values.private,
          unitId: reservationDetails.unit._id
        };
      }

      if (!err) {
        Modal.confirm({
          title: toggleReply
            ? intl.get('reservations.airbnbReview.message.submitReply').d('Confirm submit reply to the guest?')
            : intl.get('reservations.airbnbReview.message.submitReview').d('Confirm submit review to the guest?'),
          content: intl
            .get('reservations.airbnbReview.message.submitContent')
            .d('You will not be able to undo this action, but you may edit the review in airbnb website.'),
          onOk() {
            Promise.all([updateAirbnbReview(toggleReply ? guestToHostReviewId[0].id : hostToGuestReviewId[0].id, formattedPayload)])
              .then(() => {
                message.success(
                  toggleReply
                    ? intl.get('reservations.airbnbReview.message.replySubmitted').d('Reply Submitted')
                    : intl.get('reservations.airbnbReview.message.reviewSubmitted').d('Review Submitted!')
                );
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnTrxnUpdateSubmit = event => {
    // const { selectedRecord } = this.state;
    const { form } = this.props;
    const { reservationDetails } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const addOnTransactions = reservationDetails.transactions.filter(transaction => transaction.type === 'addon');
    event.stopPropagation();
    event.preventDefault();
    let addOnUpdate = [];
    let addOnCreate = [];
    let addOnDelete = [];

    form.validateFieldsAndScroll((err, values) => {
      let formattedPayloadsUpdate = [];
      let formattedPayloadsCreate = [];
      let formattedPayloadsDelete = [];
      let reservationAddOnPayload = [];
      Object.keys(values.addOn).map(key => {
        let paymentCharge = 0.0;
        if (values.addOn[key]._id !== '') {
          addOnTransactions.forEach(transaction => {
            if (transaction._id === values.addOn[key]._id) {
              paymentCharge = transaction.paymentCharges;
            }
          });
          if (values.addOn[key].pax === 0) {
            const formattedPayload = {
              _id: values.addOn[key]._id,
              type: 'addon'
            };
            formattedPayloadsDelete.push(formattedPayload);
          } else {
            const formattedPayload = {
              amount: values.addOn[key].amount - paymentCharge,
              pax: values.addOn[key].pax,
              id: values.addOn[key]._id,
              type: 'addon'
            };
            formattedPayloadsUpdate.push(formattedPayload);
          }
        } else if (values.addOn[key].pax !== 0 && values.addOn[key].amount !== 0) {
          const formattedPayload = {
            date: moment(Date.now()).format('YYYY-MM-DD'),
            subType: key,
            amount: values.addOn[key].amount,
            pax: values.addOn[key].pax,
            type: 'addon',
            reservationId: this.props.match.params.id
          };
          formattedPayloadsCreate.push(formattedPayload);
        }
      });

      formattedPayloadsCreate.forEach(payload => {
        const data = {
          activity: payload.subType,
          amount: payload.amount,
          pax: payload.pax
        };
        reservationAddOnPayload.push(data);
      });

      const formattedPayload = {
        startDate: values.checkInDate.format('YYYY-MM-DD'),
        endDate: values.checkOutDate.format('YYYY-MM-DD'),
        remarks: values.remarks,
        bookingStatus: values.bookingStatus,
        priceDetails: {
          addOns: reservationAddOnPayload
        }
      };

      if (!err) {
        Modal.confirm({
          title: intl.get('reservations.reservationDetails.placeholder.saveTitle').d('Are you sure you want to overwrite existing data?'),
          content: intl
            .get('reservations.reservationDetails.placeholder.saveContent')
            .d(
              'You will not be able to undo this action, but you may update it again. \n\nPlease note that changing dates for any reservation from OTAs (Airbnb, Booking.com, etc) will only reflect in HostPlatform.'
            ),
          onOk() {
            for (let i = 0; i < formattedPayloadsUpdate.length; i++) {
              const updateAddOnPromise = putUpdateTrxn(formattedPayloadsUpdate[i]);
              addOnUpdate.push(updateAddOnPromise);
            }
            for (let i = 0; i < formattedPayloadsCreate.length; i++) {
              const createAddOnPromise = postCreateTrxn(formattedPayloadsCreate[i]);
              addOnCreate.push(createAddOnPromise);
            }
            for (let i = 0; i < formattedPayloadsDelete.length; i++) {
              const deleteAddOnPromise = deleteTrxn({ id: formattedPayloadsDelete[i]._id, type: formattedPayloadsDelete[i].type });
              addOnDelete.push(deleteAddOnPromise);
            }

            Promise.all(addOnUpdate, addOnCreate, addOnDelete, [updateReservation(id, formattedPayload)])
              .then(() => {
                message.success(intl.get('reservations.reservationDetails.placeholder.updateSucess').d('Update Sucess !'));
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const reservationId = this.props.match.params.id;
    const history = this.props.history;
    const state = this;
    Modal.confirm({
      title: intl.get('reservations.reservationDetails.placeholder.deleteTitle').d('Are you sure you want to delete this reservation?'),
      content: intl
        .get('reservations.reservationDetails.placeholder.deleteContent')
        .d('This action cannot be reversed. Once deleted, it cannot be recovered.'),
      onOk() {
        state.setState({ isDeleteButtonLoading: true });
        deleteReservation(reservationId)
          .then(res => {
            if (res.status === 204) {
              history.push('/reservation');
              message.success(intl.get('reservations.reservationDetails.placeholder.deletedMsg1').d('Reservation deleted!'));
            } else if (res.status === 403) {
              message.warning(
                intl.get('reservations.reservationDetails.placeholder.deletedMsg2').d('Reservation cannot be deleted due to dependency.')
              );
            } else {
              message.error(
                intl.get('reservations.reservationDetails.placeholder.deletedMsg3').d('Something went wrong and reservation is not deleted.')
              );
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  handleOnDepositFieldChange = e => {
    e.preventDefault();
    this.setState({
      depositCollectedForTrxn: e.target.value
    });
  };

  handleOnClickedRefundDepositBtn = e => {
    e.preventDefault();
    const { reservationDetails } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const today = new Date();
    const formattedPayload = {
      date: moment(today).format('YYYY-MM-DD'),
      amount: reservationDetails.depositCollected,
      remarks: `${intl.get('reservations.reservationDetails.headerLabels.depositRefundedOn').d('Deposit refunded on ')} ${moment(today).format(
        'YYYY-MM-DD'
      )}`,
      reservationId: id,
      type: 'deposit',
      subType: 'refund'
    };

    Modal.confirm({
      title: intl.get('reservations.reservationDetails.placeholder.refundTitle').d('Are you sure you want to make the refund?'),
      content: intl
        .get('reservations.reservationDetails.placeholder.refundContent')
        .d('Once the deposit is refunded, we will consider this reservation deal is close and will proceed to calculate for your payout.'),
      onOk() {
        postCreateTrxn(formattedPayload)
          .then(() => {
            message.success(intl.get('reservations.reservationDetails.placeholder.refundMsg1').d('Deposit refunded!'));
            window.location.reload();
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  handleOnClickedCollectDepositBtn = () => {
    const { depositCollectedForTrxn } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const today = new Date();

    if (!Number(depositCollectedForTrxn) > 0) {
      this.setState({
        depositCollectedForTrxnErrMsg: intl.get('reservations.reservationDetails.placeholder.refundMsg2').d('Deposit cannot be zero.')
      });
      return;
    }

    const formattedPayload = {
      date: moment(today).format('YYYY-MM-DD'),
      amount: depositCollectedForTrxn,
      remarks: `${intl.get('reservations.reservationDetails.headerLabels.depositCollectedOn').d('Deposit collected on ')} ${moment(today).format(
        'YYYY-MM-DD'
      )}`,
      reservationId: id,
      type: 'deposit',
      subType: 'collect'
    };

    Modal.confirm({
      title:
        intl.get('reservations.reservationDetails.placeholder.collectTitle').d(`Are you sure you want to collect `) +
        'RM' +
        intl
          .get('reservations.reservationDetails.placeholder.collectTitle2', {
            depositCollectedForTrxn: depositCollectedForTrxn
          })
          .d(`${depositCollectedForTrxn} as deposit?`),
      content: intl.get('reservations.reservationDetails.placeholder.collectContent').d('You can refund this security deposit you collected later.'),
      onOk() {
        postCreateTrxn(formattedPayload)
          .then(() => {
            message.success(intl.get('reservations.reservationDetails.placeholder.refundMsg1').d('Deposit refunded!'));
            window.location.reload();
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  handleOnClickedDeductDepositBtn = e => {
    e.preventDefault();
    const { reservationDetails } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const today = new Date();
    const formattedPayload = {
      date: moment(today).format('YYYY-MM-DD'),
      amount: reservationDetails.depositCollected,
      remarks: `${intl.get('reservations.reservationDetails.headerLabels.depositDeductedOn').d('Deposit deducted on ')} ${moment(today).format(
        'YYYY-MM-DD'
      )}`,
      reservationId: id,
      type: 'payment',
      subType: 'rental',
      payment: {
        method: 'cash'
      }
    };

    Modal.confirm({
      title: intl.get('reservations.reservationDetails.placeholder.depositeDeductedTitle').d('Are you sure you want to deduct the deposit?'),
      content: intl
        .get('reservations.reservationDetails.placeholder.deductContent')
        .d('Once the deposit is deducted, we will consider this reservation deal is close and will proceed to calculate for your payout.'),
      onOk() {
        postCreateTrxn(formattedPayload)
          .then(() => {
            message.success(intl.get('reservations.reservationDetails.placeholder.deductMsg1').d('Deposit deducted!'));
            window.location.reload();
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  getTotalAmountOfThisReservation = (charges, taxes, originalRental, addOns, addStripeCharges = false) => {
    let total = 0;
    if (charges && Object.keys(charges).length > 0) {
      Object.keys(charges).forEach(charge => {
        // if (charge === 'stripeCharges' && addStripeCharges) {
        //   total += charges[charge];
        // }
        total += charges[charge];
      });
    }
    if (taxes && Object.keys(taxes).length > 0) {
      Object.keys(taxes).forEach(tax => {
        total += taxes[tax];
      });
    }
    if (addOns && Object.keys(addOns).length > 0) {
      Object.keys(addOns).forEach(addOn => {
        total += addOns[addOn];
      });
    }
    //Pending see whether need to add charges.paymentCharges to total amount
    // if (charges && originalRental) {
    //   total = total - charges.rental + originalRental;
    // }
    return total;
  };

  getStudentStaffId = reservationDetails => {
    let studentId, staffId;
    if (reservationDetails.guestDetails && reservationDetails.guestDetails.userProfile) {
      const userProfile = reservationDetails.guestDetails.userProfile;
      if (userProfile.studentId) {
        studentId = userProfile.studentId;
      }
      if (userProfile.staffId) {
        staffId = userProfile.staffId;
      }
    }
    return { studentId, staffId };
  };

  getOutstandingOfThisReservation = balances => {
    let outstandingAmt = 0;
    if (balances && Object.keys(balances).length > 0) {
      Object.keys(balances).forEach(balance => {
        outstandingAmt += balances[balance];
      });
    }
    return outstandingAmt;
  };

  setQueryToLink = activeTab => {
    const { history } = this.props;
    history.push({
      search: `?selectedTab=${activeTab}`
    });
  };

  renderItemsProvided = () => {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { defaultItemsProvidedKeys, defaultItemsProvided } = this.state;
    getFieldDecorator('itemsProvidedKeys', { initialValue: defaultItemsProvidedKeys });
    const itemsProvidedKeys = getFieldValue('itemsProvidedKeys');
    return itemsProvidedKeys.map((k, index) => {
      return (
        <Fragment key={index}>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.itemName').d('Item Name')} required={true} key={`item[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`item[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultItemsProvided[k] && defaultItemsProvided[k].item && defaultItemsProvided[k].item,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.itemName').d('Please input item name.')
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.itemName').d('Item Name')} onBlur={this.handleOnitemsProvidedSubmit} />)}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.itemLabel').d('Label')} required={false} key={`label[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`label[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultItemsProvided[k] && defaultItemsProvided[k].label && defaultItemsProvided[k].label,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.itemLabel').d('Please input label.')
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.itemLabel').d('Label')} onBlur={this.handleOnitemsProvidedSubmit} />)}
                </div>
              </FormItem>
            </Col>
          </Row>

          <FormItem className="guest-input" required={false} key={`itemsProvided[${k}]`}>
            <div className="booking-form-item-control-wrapper">
              <Popconfirm
                title={intl.get('booking_form.guestDetails.areYouSureRemoveItem').d('Are you sure you want to remove this item?')}
                onConfirm={() => this.removeItemsProvided(k)}
                okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
              >
                <Button className="booking-form-danger" type="danger" style={{ width: '100%' }}>
                  <Icon type="minus" /> {intl.get('booking_form.guestDetails.removeItem').d('Remove item')}
                </Button>
              </Popconfirm>
            </div>
          </FormItem>
        </Fragment>
      );
    });
  };

  increment = (name, price, _id) => {
    const { form } = this.props;
    const fields = form.getFieldsValue();
    const { addOn } = fields;
    addOn[name] = {
      pax: form.getFieldValue(`addOn.${name}.pax`) + 1,
      amount: (form.getFieldValue(`addOn.${name}.pax`) + 1) * price,
      _id: _id
    };
    // const { amount } = this.state;
    form.setFieldsValue({ ...addOn, addOn });
  };

  decrease = (name, price, _id) => {
    const { form } = this.props;
    const fields = form.getFieldsValue();
    const { addOn } = fields;
    if (form.getFieldValue(`addOn.${name}.pax`) > 0) {
      addOn[name] = {
        pax: form.getFieldValue(`addOn.${name}.pax`) - 1,
        amount: (form.getFieldValue(`addOn.${name}.pax`) - 1) * price,
        _id: _id
      };
      // console.log(1205, dec);
      form.setFieldsValue({
        ...addOn,
        addOn
      });
    }
  };

  renderAddOns = () => {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { reservationDetails } = this.state;
    const { defaultAddOns, currency } = this.state;
    const addOnTransactions = reservationDetails.transactions.filter(transaction => transaction.type === 'addon');
    let temp = defaultAddOns;
    addOnTransactions.forEach(transaction => {
      for (let i = 0; i < defaultAddOns.length; i++) {
        if (defaultAddOns[i].activity === transaction.subType) {
          temp[i].pax = transaction.pax;
          temp[i].amount = Number(transaction.amount + (transaction.paymentCharges || 0)).toFixed(0);
          temp[i]._id = transaction._id;
        }
      }
    });
    return (
      // <AddOnTab reservationId={id} transactions={reservationDetails.transactions} addOns={defaultAddOns} addOnKeys={addOnKeys} />
      <Card title={intl.get('reservations.transactions.headerLabels.addOns').d('Add-ons')} type="inner">
        <Fragment>
          <Row justify="start" gutter={8} style={{ paddingBottom: 16, color: 'grey' }}>
            <Col span={6}>
              <label>{intl.get('reservations.transactions.headerLabels.activities').d('Activities')}</label>
            </Col>
            <Col span={6}>
              <label>{intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')} </label>
            </Col>
            <Col span={6}>
              <label>{intl.get('reservations.transactions.headerLabels.pax').d('No. of Pax')}</label>
            </Col>
            <Col span={6}>
              <label>
                {intl.get('reservations.transactions.headerLabels.totalAmount').d('Total Amount')} ({currency ? currency : 'RM'})
              </label>
            </Col>
          </Row>
          {temp ? (
            temp.map(addOn => (
              <Row key={addOn.activity} justify="start" gutter={8}>
                <Col span={6}>
                  <FormItem>
                    {getFieldDecorator(`addOn.${addOn.activity}._id`, {
                      initialValue: addOn._id
                    })}
                    <label>{addOn.activity}</label>
                  </FormItem>
                </Col>

                <Col span={6} style={{ paddingTop: '10px' }}>
                  <label>{generateDisplayFee(addOn.pax === 0 ? addOn.amount : addOn.amount / addOn.pax, true, 0, currency)} </label>
                </Col>
                <Col span={6}>
                  <FormItem style={{ padding: '0px 50px 0px 0px' }} className="inputaddon">
                    {getFieldDecorator(`addOn.${addOn.activity}.pax`, {
                      initialValue: addOn.pax
                    })(
                      <Input
                        type="number"
                        addonBefore={
                          <Icon
                            type="minus"
                            onClick={() => this.decrease(addOn.activity, addOn.pax === 0 ? addOn.amount : addOn.amount / addOn.pax, addOn._id)}
                          />
                        }
                        addonAfter={
                          <Icon
                            type="plus"
                            style={{ color: '#fff' }}
                            onClick={() => this.increment(addOn.activity, addOn.pax === 0 ? addOn.amount : addOn.amount / addOn.pax, addOn._id)}
                          />
                        }
                        style={{ width: '100%', textAlign: 'center' }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem>
                    {' '}
                    {getFieldDecorator(`addOn.${addOn.activity}.amount`, {
                      initialValue: addOn.pax === 0 ? 0 : addOn.amount
                    })}
                    <label> {generateDisplayFee(getFieldValue(`addOn.${addOn.activity}.amount`), true, 0, currency)} </label>
                  </FormItem>
                </Col>
              </Row>
            ))
          ) : (
            <Skeleton active />
          )}
        </Fragment>
      </Card>
    );
  };

  renderAirbnbReviews = (toggle, reply) => {
    const { form } = this.props;
    const { airbnbReviews, cleanlinessVisibility, respectVisibility, communicationVisibility, toggleReply } = this.state;
    const guestReview = airbnbReviews.filter(review => review.reviewer_role === 'guest');
    const hostReview = airbnbReviews.filter(review => review.reviewer_role === 'host');

    return (
      <Card title={intl.get('reservations.airbnbReview.headerLabels.airbnb').d('Airbnb Reviews')} type="inner">
        {guestReview[0].submitted === true && (
          <Fragment>
            <Row justify="start" gutter={8} style={{ paddingBottom: 16, color: 'grey' }}>
              <label>{intl.get('reservations.airbnbReview.headerLabels.guestReview').d('Guest Review')}</label>
              {guestReview[0].category_ratings.map(category => {
                return (
                  <>
                    <Row type="flex">
                      <FormItem label={this.capitalizeFirstLetter(category.category.toString())} />
                      <Rate value={category.rating} disabled={true} />
                    </Row>
                    <Row style={{ marginTop: -16, paddingBottom: 16, color: 'grey' }}>
                      {category.review_category_tags &&
                        category.review_category_tags.length > 0 &&
                        category.review_category_tags.map(tag =>
                          AIRBNB_GUEST_REVIEW_CATEGORY.filter(review => review.tag.toUpperCase() === tag.toUpperCase()).map(review => {
                            return (
                              <Tag color="blue">{intl.get(`reservations.airbnbReview.reviewDesc.${review.description}`).d(review.description)}</Tag>
                            );
                          })
                        )}
                    </Row>
                  </>
                );
              })}
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.overall').d('Overall Rating')}>
                <Rate value={guestReview[0].overall_rating} disabled={true} />
              </FormItem>
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.publicReview').d('Public Review')} colon={false}>
                {form.getFieldDecorator('public', {
                  initialValue: guestReview[0].public_review
                })(
                  <Input.TextArea
                    placeholder={intl.get('reservations.airbnbReview.placeholder.review').d('Review')}
                    name="public"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    readOnly={true}
                  />
                )}
              </FormItem>
              {guestReview[0].reviewee_response && (
                <FormItem label={intl.get('reservations.airbnbReview.headerLabels.response').d('Your response to the guest review')} colon={false}>
                  {form.getFieldDecorator('response', {
                    initialValue: guestReview[0].reviewee_response
                  })(
                    <Input.TextArea
                      placeholder={intl.get('reservations.airbnbReview.placeholder.response').d('Response')}
                      name="response"
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      readOnly={true}
                    />
                  )}
                </FormItem>
              )}
              {toggleReply === false && (
                <Button type="primary" onClick={e => reply(e)}>
                  {intl.get('reservations.airbnbReview.headerLabels.reply').d('Reply')}
                </Button>
              )}
              {toggleReply && (
                <FormItem label={intl.get('reservations.airbnbReview.headerLabels.reply').d('Reply')} colon={false}>
                  {form.getFieldDecorator('reply', {
                    initialValue: ''
                  })(
                    <Input.TextArea
                      placeholder={intl.get('reservations.airbnbReview.headerLabels.reply').d('Reply')}
                      name="reply"
                      autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                  )}
                </FormItem>
              )}
            </Row>
          </Fragment>
        )}
        <div className="reviewDivider" />
        {hostReview[0].submitted === true && (
          <Fragment>
            <Row justify="start" gutter={8} style={{ paddingBottom: 16, color: 'grey' }}>
              <label>{intl.get('reservations.airbnbReview.headerLabels.hostReview').d('Host Review')}</label>
              {hostReview[0].category_ratings.map(category => {
                return (
                  <>
                    <Row type="flex">
                      <FormItem
                        label={intl
                          .get(
                            `reservations.airbnbReview.headerLabels.${this.capitalizeFirstLetter(category.category.toString().replace(/_/g, ' '))}`
                          )
                          .d(this.capitalizeFirstLetter(category.category.toString().replace(/_/g, ' ')))}
                      />
                      <Rate value={category.rating} disabled={true} />
                    </Row>
                    <Row style={{ marginTop: -16, paddingBottom: 16, color: 'grey' }}>
                      {category.review_category_tags &&
                        category.review_category_tags.length > 0 &&
                        category.review_category_tags.map(tag =>
                          AIRBNB_GUEST_REVIEW_CATEGORY.filter(review => review.tag.toUpperCase() === tag.toUpperCase()).map(review => {
                            return (
                              <Tag color="blue">{intl.get(`reservations.airbnbReview.reviewDesc.${review.description}`).d(review.description)}</Tag>
                            );
                          })
                        )}
                    </Row>
                  </>
                );
              })}
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.publicReview').d('Public Review')} colon={false}>
                {form.getFieldDecorator('public', {
                  initialValue: hostReview[0].public_review
                })(
                  <Input.TextArea
                    placeholder={intl.get('reservations.airbnbReview.placeholder.review').d('Review')}
                    name="public"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    readOnly={true}
                  />
                )}
              </FormItem>
            </Row>
          </Fragment>
        )}
        {!hostReview[0].submitted === true && (
          <Fragment>
            <label>{intl.get('reservations.airbnbReview.headerLabels.yourReview').d('Your Review')}</label>
            <Row justify="start" gutter={8} style={{ paddingBottom: 16, color: 'grey' }}>
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.cleanliness').d('Cleanliness')} required={true}>
                {form.getFieldDecorator(`cleanlinessRating`, {
                  initialValue: 0,
                  rules: [
                    {
                      required: true,
                      message: intl
                        .get('reservations.airbnbReview.message.giveRating')
                        .d('Please give a rating for the guest on their cleanliness during their stay.')
                    }
                  ]
                })(<Rate onChange={e => toggle(e, 'cleanliness')} />)}
              </FormItem>
              {cleanlinessVisibility && (
                <>
                  <FormSelection
                    form={form}
                    multipleMode={true}
                    isHideLabel={true}
                    showArrow={true}
                    name="cleanliness"
                    className="cleanlinessRadioBtn"
                    placeholder={intl
                      .get('reservations.airbnbReview.placeholder.rate')
                      .d('You may rate your guest with these categories. (Optional)')}
                    selections={AIRBNB_REVIEW_CATEGORY.filter(review => review.category === 'cleanliness').map(review => {
                      return {
                        key: review.tag,
                        value: review.tag,
                        displayValue: intl.get(`reservations.airbnbReview.reviewDesc.${review.description}`).d(review.description)
                      };
                    })}
                    defaultValue={[]}
                    optionFilterProp="filtervalue"
                  />
                  <FormItem label={intl.get('reservations.airbnbReview.headerLabels.addComment').d('Additonal Comment')} colon={false}>
                    {form.getFieldDecorator('cleanlinessComment', { initialValue: '' })(
                      <Input.TextArea
                        placeholder={intl.get('reservations.airbnbReview.placeholder.comment').d('Comment')}
                        name="cleanlinessComment"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  </FormItem>
                </>
              )}
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.respectHouseRule').d('Respecting House Rules')} required={true}>
                {form.getFieldDecorator(`respectRating`, {
                  initialValue: 0,
                  rules: [
                    {
                      required: true,
                      message: intl
                        .get('reservations.airbnbReview.message.giveGuestRating')
                        .d('Please give a rating for the guest whether they have been respectful towards your house rules.')
                    }
                  ]
                })(<Rate onChange={e => toggle(e, 'respect')} />)}
              </FormItem>
              {respectVisibility && (
                <>
                  <FormSelection
                    form={form}
                    multipleMode={true}
                    isHideLabel={true}
                    showArrow={true}
                    name="respect"
                    className="respectRadioBtn"
                    placeholder={intl
                      .get('reservations.airbnbReview.placeholder.rate')
                      .d('You may rate your guest with these categories. (Optional)')}
                    selections={AIRBNB_REVIEW_CATEGORY.filter(review => review.category === 'respect_house_rules').map(review => {
                      return {
                        key: review.tag,
                        value: review.tag,
                        displayValue: intl.get(`reservations.airbnbReview.reviewDesc.${review.description}`).d(review.description)
                      };
                    })}
                    defaultValue={[]}
                    optionFilterProp="filtervalue"
                  />
                  <FormItem label={intl.get('reservations.airbnbReview.headerLabels.addComment').d('Additonal Comment')} colon={false}>
                    {form.getFieldDecorator('respectComment', { initialValue: '' })(
                      <Input.TextArea
                        placeholder={intl.get('reservations.airbnbReview.placeholder.comment').d('Comment')}
                        name="respectComment"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  </FormItem>
                </>
              )}
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.communication').d('Communication')} required={true}>
                {form.getFieldDecorator(`communicationRating`, {
                  initialValue: 0,
                  rules: [
                    {
                      required: true,
                      message: intl
                        .get('reservations.airbnbReview.message.communicationRating')
                        .d('Please give a rating for the guest based on their communications with you.')
                    }
                  ]
                })(<Rate onChange={e => toggle(e, 'communication')} />)}
              </FormItem>
              {communicationVisibility && (
                <>
                  <FormSelection
                    form={form}
                    multipleMode={true}
                    isHideLabel={true}
                    showArrow={true}
                    name="communication"
                    className="communicationRadioBtn"
                    placeholder={intl
                      .get('reservations.airbnbReview.placeholder.rate')
                      .d('You may rate your guest with these categories. (Optional)')}
                    selections={AIRBNB_REVIEW_CATEGORY.filter(review => review.category === 'communication').map(review => {
                      return {
                        key: review.tag,
                        value: review.tag,
                        displayValue: intl.get(`reservations.airbnbReview.reviewDesc.${review.description}`).d(review.description)
                      };
                    })}
                    defaultValue={[]}
                    optionFilterProp="filtervalue"
                  />
                  <FormItem label={intl.get('reservations.airbnbReview.headerLabels.addComment').d('Additonal Comment')} colon={false}>
                    {form.getFieldDecorator('communicationComment', { initialValue: '' })(
                      <Input.TextArea
                        placeholder={intl.get('reservations.airbnbReview.placeholder.comment').d('Comment')}
                        name="communicationComment"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  </FormItem>
                </>
              )}
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.privateFeedback').d('Private Feedback')} colon={false}>
                {form.getFieldDecorator('private', { initialValue: '' })(
                  <Input.TextArea
                    placeholder={intl.get('reservations.airbnbReview.placeholder.feedback').d('Feedback (Optional)')}
                    name="private"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                )}
              </FormItem>
              <FormItem label={intl.get('reservations.airbnbReview.headerLabels.publicReview').d('Public Review')} colon={false}>
                {form.getFieldDecorator('public', {
                  initialValue: '',
                  rules: [
                    {
                      message: intl.get('reservations.airbnbReview.message.publicMsg').d('Please key in a review message for the guest')
                    }
                  ]
                })(
                  <Input.TextArea
                    placeholder={intl.get('reservations.airbnbReview.placeholder.review').d('Review')}
                    name="public"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                )}
              </FormItem>
              <FormRadioButton
                form={form}
                formLabel={intl.get('reservations.airbnbReview.headerLabels.recommend').d('Would you recommend this guest to other hosts?')}
                name="recommend"
                className="recommendRadioBtn"
                buttonStyle="solid"
                defaultValue={true}
                requiredErrorMessage={intl.get('reservations.airbnbReview.placeholder.yesOrNo').d('Please select Yes or No')}
                selections={[
                  { key: true, value: true, displayValue: intl.get('listings.bookingEngine.placeholder.yes').d('Yes') },
                  { key: false, value: false, displayValue: intl.get('listings.bookingEngine.placeholder.no').d('No') }
                ]}
              />
            </Row>
          </Fragment>
        )}
      </Card>
    );
  };

  checkNationalityMY = k => {
    const { form } = this.props;
    const nationality = form.getFieldValue(`nationality[${k}]`);
    return nationality === 'MY';
  };

  renderGuestDetails = () => {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { countryOptions, stateOptions, sponsorOptions, defaultOccupantKeys, defaultOccupants, defaultHost } = this.state;
    getFieldDecorator('occupantKeys', { initialValue: defaultOccupantKeys });
    const keys = getFieldValue('occupantKeys');
    return keys.map((k, index) => {
      return (
        <Fragment key={index}>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.firstName').d('First Name')} required={true} key={`firstName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`firstName[${k}]`, {
                    initialValue: defaultOccupants[k] && defaultOccupants[k].firstName,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.firstName').d("Please input guest's first name.")
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.firstName').d('First Name')} onBlur={this.handleOnGuestSubmit} />)}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.lastName').d('Last Name')} required={true} key={`lastName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`lastName[${k}]`, {
                    initialValue: defaultOccupants[k] && defaultOccupants[k].lastName,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.lastName').d("Please input guest's last name.")
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.lastName').d('Last Name')} onBlur={this.handleOnGuestSubmit} />)}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.nationality').d('Nationality (Optional)')} key={`nationality${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`nationality[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue:
                      defaultOccupants[k] && defaultOccupants[k].nationality
                        ? defaultOccupants[k].nationality
                        : intl.get('booking_form.guestDetails.alertMessage.notSpecified').d('Not Specified')
                  })(
                    <Select
                      showSearch
                      placeholder={intl.get('booking_form.guestDetails.placeholder.selectCountry').d('Select a Country')}
                      optionFilterProp="children"
                      onBlur={this.handleOnGuestSubmit}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {countryOptions.map(countries => (
                        <Option value={countries.value} key={countries.value}>
                          {intl.get(`countries.${countries.label.replace(/\./g, '_')}`).d(countries.label)}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem
                label={
                  <span>
                    {intl.get('reservations.guestDetails.headerLabels.contactNumber').d('Contact Number ')}
                    <Tooltip
                      title={
                        <>
                          <span style={{ fontSize: '11px' }}>
                            {intl
                              .get('reservations.guestDetails.headerLabels.whatsappMsg')
                              .d('If you edited the Contact Number, please click Save to generate a new WhatsApp link.')}
                          </span>
                          <br />
                          <span style={{ color: 'silver', fontSize: '11px', fontStyle: 'italic' }}>
                            {intl.get('reservations.guestDetails.headerLabels.whatsappExample').d('Example for MY number: +60123456789')}
                          </span>
                        </>
                      }
                    >
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                key={`contactNo[${k}]`}
              >
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`contactNo[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultOccupants[k] && defaultOccupants[k].contactNo,
                    rules: [
                      {
                        whitespace: true,
                        pattern: CONTACT_NUMBER_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.contactNumber').d("Please input guest's contact number.")
                      }
                    ]
                  })(
                    <Input placeholder={intl.get('booking_form.guestDetails.contactNumber').d('Contact Number')} onBlur={this.handleOnGuestSubmit} />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.state').d('State (Optional)')} key={`state[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {this.checkNationalityMY(k)
                    ? getFieldDecorator(`state[${k}]`, { initialValue: defaultOccupants[k] && defaultOccupants[k].state })(
                        <Select
                          showSearch
                          placeholder={intl.get('booking_form.guestDetails.placeholder.selectState').d('Select a State')}
                          optionFilterProp="children"
                          onBlur={this.handleOnGuestSubmit}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {stateOptions.map(state => (
                            <Option value={state.value} key={state.value}>
                              {intl.get(`statesMY.${state.label.replace(/\./g, '_')}`).d(state.label)}
                            </Option>
                          ))}
                        </Select>
                      )
                    : getFieldDecorator(`state[${k}]`, { initialValue: defaultOccupants[k] && defaultOccupants[k].state })(
                        <Input placeholder={intl.get('booking_form.guestDetails.state').d('State (Optional)')} onBlur={this.handleOnGuestSubmit} />
                      )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.email').d('E-mail (Optional)')} key={`email[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`email[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultOccupants[k] && defaultOccupants[k].email,
                    rules: [
                      {
                        pattern: EMAIL_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.email').d('Please enter a valid email address.')
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.email').d('E-mail')} type="email" onBlur={this.handleOnGuestSubmit} />)}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24}>
              <FormItem label={intl.get('booking_form.guestDetails.ic').d('IC/Passport No, (Optional)')} key={`icNo[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`icNo[${k}]`, { initialValue: defaultOccupants[k] && defaultOccupants[k].icNo })(
                    <Input placeholder={intl.get('booking_form.guestDetails.ic').d('IC/Passport No')} onBlur={this.handleOnGuestSubmit} />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            {SPECIAL_HOST_IDS.includes(defaultHost) && defaultOccupants[k].gender && (
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.gender').d('Gender')} key={`gender[${k}]`}>
                  <div className="guest-input-control-wrapper">
                    {getFieldDecorator(`gender[${k}]`, {
                      initialValue:
                        defaultOccupants[k] && defaultOccupants[k].gender
                          ? defaultOccupants[k].gender
                          : intl.get('booking_form.guestDetails.alertMessage.notSpecified').d('Not Specified')
                    })(<Input placeholder={intl.get('booking_form.guestDetails.gender').d('Gender')} disabled />)}
                  </div>
                </FormItem>
              </Col>
            )}
            {SPECIAL_HOST_IDS.includes(defaultHost) && defaultOccupants[k].studentId && (
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.studentId').d('Student ID')} key={`studentId[${k}]`}>
                  <div className="guest-input-control-wrapper">
                    {getFieldDecorator(`studentId[${k}]`, { initialValue: defaultOccupants[k] && defaultOccupants[k].studentId })(
                      <Input placeholder={intl.get('booking_form.guestDetails.studentId').d('Student ID')} onBlur={this.handleOnGuestSubmit} />
                    )}
                  </div>
                </FormItem>
              </Col>
            )}
            {SPECIAL_HOST_IDS.includes(defaultHost) && defaultOccupants[k].staffId && (
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.staffId').d('Staff ID')} key={`staffId[${k}]`}>
                  <div className="guest-input-control-wrapper">
                    {getFieldDecorator(`staffId[${k}]`, { initialValue: defaultOccupants[k] && defaultOccupants[k].staffId })(
                      <Input placeholder={intl.get('booking_form.guestDetails.staffId').d('Staff ID')} onBlur={this.handleOnGuestSubmit} />
                    )}
                  </div>
                </FormItem>
              </Col>
            )}
            {SPECIAL_HOST_IDS.includes(defaultHost) && (
              <Col span={24} lg={12}>
                <FormItem label={intl.get('booking_form.guestDetails.sponsoredBy').d('Sponsored By')} key={`sponsoredBy[${k}]`}>
                  <div className="guest-input-control-wrapper">
                    {getFieldDecorator(`sponsoredBy[${k}]`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: defaultOccupants[k] && defaultOccupants[k].sponsoredBy
                    })(
                      <Select
                        showSearch
                        allowClear
                        placeholder={intl.get('booking_form.guestDetails.placeholder.selectSponsor').d('Select a Sponsor')}
                        optionFilterProp="children"
                        onBlur={this.handleOnGuestSubmit}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {sponsorOptions.map(sponsor => (
                          <Option value={sponsor.value} key={sponsor.value}>
                            {intl.get(`sponsors.${sponsor.label}`).d(sponsor.label)}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </FormItem>
              </Col>
            )}
          </Row>
          <FormItem className="guest-input">
            {keys.length > 1 ? (
              <div className="guest-input-control-wrapper">
                <Popconfirm
                  title={intl.get('booking_form.guestDetails.areYouSureRemoveGuest').d('Are you sure you want to remove this guest?')}
                  onConfirm={() => this.removeGuest(k)}
                  okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                  onBlur={this.handleOnGuestSubmit}
                  cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
                >
                  <Button type="danger" disabled={keys.length === 1} style={{ width: '100%' }}>
                    <Icon type="minus" /> {intl.get('booking_form.guestDetails.removeGuest').d('Remove guest')}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </FormItem>
        </Fragment>
      );
    });
  };

  renderVehicle = () => {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { defaultVehicleKeys, defaultVehicles } = this.state;
    getFieldDecorator('vehicleKeys', { initialValue: defaultVehicleKeys });
    const vehicleKeys = getFieldValue('vehicleKeys');
    return vehicleKeys.map((k, index) => {
      return (
        <Fragment key={index}>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.vehicleType').d('Vehicle Type')} required={true} key={`vehicleType[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleType[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultVehicles[k] && defaultVehicles[k].vehicleType,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.vehicleType').d("Please input vehicle's type.")
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.vehicleType').d('Vehicle Type')} onBlur={this.handleOnVehicleSubmit} />)}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.vehicleModel').d('Vehicle Model')} required={true} key={`vehicleModel[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleModel[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultVehicles[k] && defaultVehicles[k].vehicleModel,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.vehicleModel').d("Please input vehicle's model.")
                      }
                    ]
                  })(
                    <Input placeholder={intl.get('booking_form.guestDetails.vehicleModel').d('Vehicle Model')} onBlur={this.handleOnVehicleSubmit} />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem
                label={intl.get('booking_form.guestDetails.vehicleRegistrationNumber').d('Vehicle Registration Number')}
                required={true}
                key={`vehicleNo[${k}]`}
              >
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleNo[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: defaultVehicles[k] && defaultVehicles[k].vehicleNo,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl
                          .get('booking_form.guestDetails.alertMessage.vehicleRegistrationNumber')
                          .d("Please input vehicle's registration number.")
                      }
                    ]
                  })(
                    <Input
                      placeholder={intl.get('booking_form.guestDetails.vehicleRegistrationNumber').d('Vehicle Registration Number')}
                      onBlur={this.handleOnVehicleSubmit}
                    />
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.vehicleColor').d('Vehicle Color')} required={false} key={`vehicleColor[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <div>
                    {getFieldDecorator(`vehicleColor[${k}]`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue:
                        defaultVehicles[k] && defaultVehicles[k].vehicleColor ? defaultVehicles[k].vehicleColor : this.state.vehicleColorOptions[0],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: intl.get('booking_form.guestDetails.alertMessage.vehicleColor').d("Please input vehicle's color.")
                        }
                      ]
                    })(
                      <Select onBlur={this.handleOnVehicleSubmit} showSearch>
                        {this.state.vehicleColorOptions.map(vehicleColorOption => {
                          return (
                            <Option value={vehicleColorOption}>
                              {intl.get(`booking_form.guestDetails.vehicleColorOptions.${vehicleColorOption}`)}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                </div>
              </FormItem>
            </Col>
          </Row>

          <FormItem className="guest-input">
            <div className="booking-form-item-control-wrapper">
              <Popconfirm
                title={intl.get('booking_form.guestDetails.areYouSureRemoveVehicle').d('Are you sure you want to remove this vehicle?')}
                onConfirm={() => this.removeVehicle(k)}
                okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
              >
                <Button className="booking-form-danger" type="danger" style={{ width: '100%' }}>
                  <Icon type="minus" />
                  {intl.get('booking_form.guestDetails.removeVehicle').d('Remove vehicle')}
                </Button>
              </Popconfirm>
            </div>
          </FormItem>
        </Fragment>
      );
    });
  };

  getCheckInDate = () => {
    const { getFieldValue } = this.props.form;
    return getFieldValue('checkInDate') ? getFieldValue('checkInDate').format('YYYY-MM-DD') : undefined;
  };

  getCheckOutDate = () => {
    const { getFieldValue } = this.props.form;
    return getFieldValue('checkOutDate') ? getFieldValue('checkOutDate').format('YYYY-MM-DD') : undefined;
  };

  getNoOfBookingNights = () => {
    const { getFieldValue } = this.props.form;
    if (!getFieldValue('checkOutDate') || !getFieldValue('checkInDate')) {
      return 0;
    }
    return getFieldValue('checkOutDate').diff(getFieldValue('checkInDate'), 'd');
  };

  handleWhatsApp = () => {
    const { defaultOccupants } = this.state;

    // only use the first valid contactNo
    const contactNoFieldValue = defaultOccupants['0'] && defaultOccupants['0'].contactNo;
    return window.open(`https://wa.me/${cleanNumber(contactNoFieldValue)}`, '_blank');
  };

  hasGuestContactNo = () => {
    const { defaultOccupants } = this.state;
    const contactNoFieldValue = defaultOccupants['0'] && defaultOccupants['0'].contactNo;

    return contactNoFieldValue && contactNoFieldValue.length > MIN_INTL_CONTACT_NO_LEN;
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  activityLogUpdatedBy = updatedBy => {
    return updatedBy ? `${updatedBy.userProfile.firstName} ${updatedBy.userProfile.lastName}` : 'N/A';
  };

  getActivityLogBookingStatus = logData => {
    if (!logData) return '-';
    const { bookingTypeOptions, bookingStatus } = this.state;
    const nonProfitBookingType = [4, 5, 6];
    if (logData.bookingType && nonProfitBookingType.includes(logData.bookingType)) {
      return intl.get(`reservations.reservationDetails.bookingStatus.${bookingTypeOptions.find(v => v.value === logData.bookingType).label}`);
    } else {
      return intl.get(`reservations.reservationDetails.bookingStatus.${bookingStatus.find(v => v.code === logData.bookingStatus).label}`);
    }

    //rollback
    /* if (logData.bookingType && nonProfitBookingType.includes(logData.bookingType)) {
      return bookingTypeOptions.find(v => v.value === logData.bookingType).label;
    } else {
      return bookingStatus.find(v => v.code === logData.bookingStatus).label;
    } */
  };

  checkDifferences = (data, index, type) => {
    var { differences, createdBy } = data;
    switch (type) {
      case 'creator':
        const isCreatorDiff = !!(
          differences.length > 0 &&
          differences.findIndex(
            d => d && d.path && d.path.length > 0 && d.path.findIndex(v => v === 'userProfile') > -1 && d.path.findIndex(v => v === '_id') > -1
          ) > -1
        );

        return (
          <Descriptions.Item
            className={isCreatorDiff ? 'activityLogDiff' : ''}
            label={
              <span style={{ fontWeight: 600 }}>
                {index === 0
                  ? intl.get('reservations.activityLogs.headerLabels.createdBy').d('Created By')
                  : intl.get('reservations.activityLogs.headerLabels.updatedBy').d('Updated By')}
              </span>
            }
          >
            {this.activityLogUpdatedBy(createdBy)}
          </Descriptions.Item>
        );

      case 'checkInDate':
        const isCheckInDateDiff = !!(
          differences.length > 0 && differences.findIndex(d => d && d.path && d.path.length > 0 && d.path.findIndex(v => v === 'startDate') > -1) > -1
        );
        return (
          <Descriptions.Item
            className={isCheckInDateDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('reservations.tableColumns.checkIn').d('Check-in Date')}</span>}
          >
            {guard(() => data.newData.startDate, '-')}
          </Descriptions.Item>
        );

      case 'checkOutDate':
        const isCheckOutDateDiff = !!(
          differences.length > 0 && differences.findIndex(d => d && d.path && d.path.length > 0 && d.path.findIndex(v => v === 'endDate') > -1) > -1
        );
        return (
          <Descriptions.Item
            className={isCheckOutDateDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('reservations.tableColumns.checkOut').d('Check-out Date')}</span>}
          >
            {guard(() => data.newData.endDate, '-')}
          </Descriptions.Item>
        );

      case 'unit':
        const isUnitDiff = !!(
          differences.length > 0 && differences.findIndex(d => d && d.path && d.path.length > 0 && d.path.findIndex(v => v === 'unit') > -1) > -1
        );
        return (
          <Descriptions.Item
            className={isUnitDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('reservations.reservationDetails.headerLabels.unit').d('Unit')}</span>}
          >
            {guard(() => data.newData.unitDetails.unitName, '-')}
          </Descriptions.Item>
        );

      case 'roomType':
        const isRoomTypeDiff = !!(
          differences.length > 0 &&
          differences.findIndex(
            d => d && d.path && d.path.length > 0 && (d.path.findIndex(v => v === 'roomType') > -1 || d.path.findIndex(v => v === 'name') > -1)
          ) > -1
        );
        return (
          <Descriptions.Item
            className={isRoomTypeDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('reservations.reservationDetails.headerLabels.roomType').d('Room Type')}</span>}
          >
            {guard(() => data.newData.unitDetails.roomType.name, '-')}
          </Descriptions.Item>
        );

      case 'status':
        const isStatusDiff = !!(
          differences.length > 0 &&
          differences.findIndex(
            d =>
              d &&
              d.path &&
              d.path.length > 0 &&
              (d.path.findIndex(v => v === 'bookingStatus') > -1 || d.path.findIndex(v => v === 'bookingType') > -1)
          ) > -1
        );
        return (
          <Descriptions.Item
            className={isStatusDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('multicalendar.headerLabels.bookingStatus').d('Booking Status')}</span>}
          >
            {this.getActivityLogBookingStatus(data.newData)}
          </Descriptions.Item>
        );

      case 'clashing':
        const isClashingDiff = !!(
          differences.length > 0 &&
          differences.findIndex(d => d && d.path && d.path.length > 0 && d.path.findIndex(v => v === 'clashingReservations') > -1) > -1
        );
        return (
          <Descriptions.Item
            className={isClashingDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('reservations.activityLogs.headerLabels.isClashing').d('Is Clashing')}</span>}
          >
            {guard(() => data.newData.clashingReservations.length, 0) > 0
              ? intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')
              : intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
          </Descriptions.Item>
        );

      default:
        break;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      checkIsAllowDeleteReservation,
      match: {
        params: { id }
      },
      location,
      checkIsAdminReadOnly,
      checkIsAdmin
    } = this.props;
    const {
      reservationDetails,
      isLoading,
      bookingStatusOptions,
      depositCollectedForTrxn,
      depositCollectedForTrxnErrMsg,
      propertyOptions,
      bookingTypeOptions,
      roomTypeOptions,
      unitOptions,
      rateModifierOptions,
      platformOptions,
      defaultBookingStatus,
      defaultStartDate,
      defaultEndDate,
      defaultHost,
      defaultProperty,
      defaultRateModifier,
      defaultNoPax,
      defaultRoomType,
      defaultUnit,
      defaultBookingType,
      defaultSource,
      defaultRemarks,
      defaultEmergencyContactName,
      defaultEmergencyContactNo,
      isOTAReservation,
      isNonProfitBookingType,
      isDeleteButtonLoading,
      isSaveButtonLoading,
      pfdCardModalVisible,
      activityLog,
      defaultAddOns,
      permissionConstants,
      airbnbReviews,
      toggleReply,
      attachments
    } = this.state;
    const query = queryString.parse(location.search);
    const { charges, taxes, outstandingAmounts, originalRental, addOns } = reservationDetails;
    const { studentId, staffId } = this.getStudentStaffId(reservationDetails);
    // Added addon as part of equation to get same value in transaction details table
    // Might need to fix decimal points for weightage of payment charges
    const totalCharges = this.getTotalAmountOfThisReservation(charges, taxes, 0, addOns, true);
    const originalTotalCharges = this.getTotalAmountOfThisReservation(charges, taxes, originalRental, addOns, true);
    const outstandingBalance = this.getOutstandingOfThisReservation(outstandingAmounts);

    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Tabs
            defaultActiveKey={query.selectedTab}
            className="reservationDetailsContainer"
            onTabClick={this.setQueryToLink}
            activeKey={query.selectedTab ? query.selectedTab : 'reservationDetails'}
            tabBarExtraContent={
              <CloseButtonReservation
                onClick={() => {
                  this.props.history.push('/reservation');
                }}
              />
            }
          >
            <TabPane
              tab={intl.get('reservations.reservationDetails.headerLabels.reservation').d('Reservation Details')}
              key="reservationDetails"
              style={{ padding: '0 16px' }}
              forceRender={true}
            >
              <Form onSubmit={this.handleSubmit} layout="horizontal">
                <Row gutter={16}>
                  <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
                    <Card
                      title={intl.get('reservations.reservationDetails.headerLabels.reservation').d('Reservation Details')}
                      extra={
                        reservationDetails._id && (
                          <LinkToReservation hostId={defaultHost} propertyId={defaultProperty} roomTypeId={defaultRoomType} date={defaultStartDate} />
                        )
                      }
                      loading={isLoading}
                    >
                      <div>
                        <FormItem label={intl.get('reservations.reservationDetails.headerLabels.status').d('Status')} colon={false}>
                          {getFieldDecorator('bookingStatus', { initialValue: defaultBookingStatus })(
                            <Select
                              showSearch
                              placeholder={intl.get('reservations.reservationDetails.placeholder.status').d('Select a booking status')}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: '100%' }}
                              disabled={isNonProfitBookingType}
                            >
                              {bookingStatusOptions}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                      <Row gutter={8}>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.checkIn').d('Check-in')} colon={false}>
                            {getFieldDecorator('checkInDate', {
                              rules: [
                                {
                                  type: 'object',
                                  required: true,
                                  message: intl.get('reservations.reservationDetails.placeholder.date').d('Please select a date!')
                                }
                              ],
                              initialValue: defaultStartDate ? moment(defaultStartDate) : undefined
                            })(
                              <DatePicker
                                name="startDate"
                                style={{ width: '100%' }}
                                // disabled={isOTAReservation}
                                disabledDate={getDisabledDate}
                                onChange={this.handleOnStartCalendarChange}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.checkOut').d('Check-out')} colon={false}>
                            {getFieldDecorator('checkOutDate', {
                              rules: [
                                {
                                  type: 'object',
                                  required: true,
                                  message: intl.get('reservations.reservationDetails.placeholder.date').d('Please select a date!')
                                }
                              ],
                              initialValue: defaultEndDate ? moment(defaultEndDate) : undefined
                            })(
                              <DatePicker
                                name="endDate"
                                style={{ width: '100%' }}
                                // disabled={isOTAReservation}
                                disabledDate={getDisabledDate}
                                onChange={this.handleOnEndCalendarChange}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <FormItem label={intl.get('reservations.reservationDetails.headerLabels.property').d('Property')} colon={false}>
                        {getFieldDecorator('property', {
                          rules: [
                            { required: true, message: intl.get('reservations.reservationDetails.placeholder.property').d('Select a property') }
                          ],
                          initialValue: defaultProperty
                        })(
                          <Select
                            // disabled
                            disabled={!this.checkIsCommercialReservation()}
                            showSearch
                            placeholder={intl.get('reservations.reservationDetails.placeholder.property').d('Select a property')}
                            optionFilterProp="children"
                            onChange={this.handleOnPropertyChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%' }}
                          >
                            {propertyOptions.map(properties => (
                              <Option value={properties.value} key={properties.value}>
                                {properties.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                      <Row gutter={8}>
                        <Col span={24} lg={12}>
                          <FormItem
                            label={intl.get('reservations.reservationDetails.headerLabels.roomRateModifier').d('Room Rate Modifier')}
                            colon={false}
                          >
                            {getFieldDecorator('rateModifier', { initialValue: defaultRateModifier || 'Web Rate' })(
                              <Select
                                disabled={true}
                                showSearch
                                placeholder={intl
                                  .get('reservations.reservationDetails.placeholder.roomRateModifier')
                                  .d('Select a room rate modifier')}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                              >
                                {rateModifierOptions.map(rateModifier => (
                                  <Option value={rateModifier.value} key={rateModifier.value}>
                                    {rateModifier.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.numberOfPax').d('Number of Pax')} colon={false}>
                            {getFieldDecorator('noPax', { initialValue: defaultNoPax })(
                              <Input name="numberOfPax" placeholder="1" onChange={this.handleOnInputChange} type="number" min={1} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.roomType').d('Room Type')} colon={false}>
                            {getFieldDecorator('roomType', {
                              rules: [
                                {
                                  required: true,
                                  message: intl.get('reservations.reservationDetails.placeholder.roomTypeRule').d('Please select a room type!')
                                }
                              ],
                              initialValue: defaultRoomType
                            })(
                              <Select
                                showSearch
                                placeholder={intl.get('reservations.reservationDetails.placeholder.roomType').d('Select a room type')}
                                optionFilterProp="children"
                                disabled={!this.checkIsCommercialReservation()}
                                onChange={this.handleOnRoomTypeChange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                              >
                                {/* {roomTypeOptions.map(roomTypes => (
                                  <Option value={roomTypes.value} key={roomTypes.value}>
                                    {roomTypes.label}
                                  </Option>
                                ))} */}

                                <OptGroup label={intl.get('booking_form.bodyLabels.availableRoomType').d('Available Room Type')}>
                                  {roomTypeOptions
                                    .filter(roomTypes => roomTypes.isAvailable)
                                    .map(roomTypes => (
                                      <Option value={roomTypes.value} key={roomTypes.value}>
                                        {roomTypes.label}
                                      </Option>
                                    ))}
                                </OptGroup>
                                <OptGroup label={intl.get('booking_form.bodyLabels.unavailableRoomType').d('Unavailable Room Type')}>
                                  {roomTypeOptions
                                    .filter(roomTypes => !roomTypes.isAvailable)
                                    .map(roomTypes => (
                                      <Option value={roomTypes.value} key={roomTypes.value} disabled>
                                        {roomTypes.label}
                                      </Option>
                                    ))}
                                </OptGroup>
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.unitListing').d('Unit Listing')} colon={false}>
                            {getFieldDecorator('unit', {
                              rules: [
                                {
                                  required: true,
                                  message: intl.get('reservations.reservationDetails.placeholder.unitRule').d('Please select a unit!')
                                }
                              ],
                              initialValue: defaultUnit
                            })(
                              <Select
                                showSearch
                                placeholder={intl.get('reservations.reservationDetails.placeholder.unit').d('Select a unit')}
                                optionFilterProp="children"
                                disabled={!this.checkIsCommercialReservation()}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                              >
                                <OptGroup label={intl.get('reservations.reservationDetails.placeholder.availableUnits').d('Available Units')}>
                                  {unitOptions
                                    .filter(units => units.isAvailable)
                                    .map(units => (
                                      <Option value={units.value} key={units.value}>
                                        {units.label}
                                      </Option>
                                    ))}
                                </OptGroup>
                                <OptGroup label={intl.get('reservations.reservationDetails.placeholder.unavailableUnits').d('Unavailable Units')}>
                                  {unitOptions
                                    .filter(units => !units.isAvailable)
                                    .map(units => (
                                      <Option value={units.value} key={units.value} disabled>
                                        {units.label}
                                      </Option>
                                    ))}
                                </OptGroup>
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.bookingType').d('Booking Type')} colon={false}>
                            {getFieldDecorator('bookingType', {
                              rules: [
                                {
                                  required: true,
                                  message: intl.get('reservations.reservationDetails.placeholder.bookingTypeRule').d('Please select a booking type!')
                                }
                              ],
                              initialValue: defaultBookingType
                            })(
                              <Select
                                disabled
                                showSearch
                                placeholder={intl.get('reservations.reservationDetails.placeholder.bookingType').d('Select a booking type')}
                                optionFilterProp="children"
                                onChange={this.handleOnInputChange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                              >
                                {bookingTypeOptions.map(bookingTypes => (
                                  <Option value={bookingTypes.value} key={bookingTypes.value}>
                                    {intl.get(`reservations.reservationDetails.bookingTypeOptions.${bookingTypes.label}`)}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        </Col>

                        <Col span={24} lg={12}>
                          <FormItem label={intl.get('reservations.reservationDetails.headerLabels.bookingSource').d('Booking Source')} colon={false}>
                            {getFieldDecorator('source', { initialValue: defaultSource ? defaultSource : 'HostPlatform' })(
                              <Select
                                disabled
                                showSearch
                                placeholder={intl.get('reservations.reservationDetails.placeholder.bookingSource').d('Select a booking source')}
                                optionFilterProp="children"
                                onChange={this.handleOnInputChange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                              >
                                {platformOptions.map(sources => (
                                  <Option value={sources.value} key={sources.value}>
                                    {sources.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>

                      <FormItem label={intl.get('reservations.reservationDetails.headerLabels.remarks').d('Remarks')} colon={false}>
                        {getFieldDecorator('remarks', { initialValue: defaultRemarks })(
                          <Input.TextArea
                            placeholder={intl.get('reservations.reservationDetails.placeholder.remarks').d('Remarks goes here')}
                            name="remarks"
                            autoSize={{ minRows: 3, maxRows: 6 }}
                          />
                        )}
                      </FormItem>

                      <FormItem label={intl.get('reservations.reservationDetails.headerLabels.attachments').d('Attachments')} colon={false}>
                        {getFieldDecorator('attachments', { initialValue: attachments })(
                          <Row type="flex" justify="left">
                            <UploadFiles
                              files={attachments}
                              onUploadFinish={this.handleOnUploadFinish}
                              onFileDelete={this.handleOnFileDelete}
                              checkIsAdmin={true}
                            />
                          </Row>
                        )}
                      </FormItem>
                    </Card>
                    <ActionButtons
                      shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                      isSaveButtonLoading={isSaveButtonLoading}
                      isDeleteButtonLoading={isDeleteButtonLoading}
                      onDelete={this.handleDelete}
                      checkIsAdminReadOnly={checkIsAdminReadOnly}
                      className="rf-buttons-center"
                    />
                  </Col>
                  <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
                    <TransactionDetails
                      isLoading={isLoading}
                      reservation={reservationDetails}
                      checkInDate={this.getCheckInDate()}
                      checkOutDate={this.getCheckOutDate()}
                      noOfNight={this.getNoOfBookingNights()}
                      totalCharges={totalCharges}
                      originalTotalCharges={originalTotalCharges}
                      outstandingBalance={outstandingBalance}
                      depositCollectedForTrxn={depositCollectedForTrxn}
                      onDepositChange={this.handleOnDepositFieldChange}
                      depositCollectedForTrxnErrMsg={depositCollectedForTrxnErrMsg}
                      onCollectBtnClick={this.handleOnClickedCollectDepositBtn}
                      onRefundBtnClick={this.handleOnClickedRefundDepositBtn}
                      onDeductBtnClick={this.handleOnClickedDeductDepositBtn}
                      pfdCardModalVisibilityToggle={this.pfdCardModalVisibilityToggle}
                      showPrice={this.authorizedItem([permissionConstants.TRANSACTION_VIEW])}
                      currency={this.state.currency}
                      checkIsAdminReadOnly={checkIsAdminReadOnly}
                      host={defaultHost}
                    />
                  </Col>
                </Row>
                <ActionButtons
                  shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                  isSaveButtonLoading={isSaveButtonLoading}
                  isDeleteButtonLoading={isDeleteButtonLoading}
                  onDelete={this.handleDelete}
                  checkIsAdminReadOnly={checkIsAdminReadOnly}
                  className="rf-buttons-bottom"
                />
              </Form>
            </TabPane>
            {(query.selectedTab === 'guestDetails' || this.checkIsCommercialReservation()) && (
              <TabPane
                tab={intl.get('reservations.guestDetails.headerLabels.guest').d('Guest Details')}
                key="guestDetails"
                style={{ padding: '0 16px' }}
                forceRender={true}
              >
                <Form onSubmit={this.handleSubmit} layout="horizontal">
                  <Row gutter={16}>
                    <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
                      <Card
                        className="reservation-card"
                        title={intl.get('reservations.guestDetails.headerLabels.guest').d('Guest Details')}
                        loading={isLoading}
                        extra={
                          <Tooltip
                            title={
                              <span style={{ fontSize: '11px' }}>
                                {intl
                                  .get('reservations.guestDetails.headerLabels.whatsappMsg')
                                  .d('If you edited the Contact Number, please click Save to generate a new WhatsApp link.')}
                              </span>
                            }
                          >
                            <Button type="primary" className="whatsapp-button" onClick={this.handleWhatsApp} disabled={!this.hasGuestContactNo()}>
                              <Icon component={WhatsAppIcon} /> {intl.get('reservations.guestDetails.headerLabels.whatsapp').d('Chat on WhatsApp')}
                            </Button>
                          </Tooltip>
                        }
                      >
                        {this.renderGuestDetails()}
                        <FormItem className="guest-input">
                          <Button type="dashed" onClick={this.addGuest} style={{ width: '100%' }}>
                            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addGuest').d('Add guest')}
                          </Button>
                        </FormItem>
                      </Card>
                      <Card
                        className="reservation-card"
                        title={intl.get('reservations.guestDetails.headerLabels.emergencyContactDetails').d('Add guest')}
                        loading={isLoading}
                      >
                        <Row gutter={8}>
                          <Col span={24} lg={12}>
                            <FormItem label={intl.get('booking_form.guestDetails.emergencyName').d('Emergency Contact Name')} colon={false}>
                              {getFieldDecorator('emergencyContactName', {
                                initialValue: defaultEmergencyContactName,
                                rules: [{}]
                              })(<Input placeholder={intl.get('booking_form.guestDetails.emergencyName').d('Emergency Contact Name')} />)}
                            </FormItem>
                          </Col>
                          <Col span={24} lg={12}>
                            <FormItem label={intl.get('booking_form.guestDetails.emergencyContactNo').d('Emergency Contact Number')} colon={false}>
                              {getFieldDecorator('emergencyContactNo', {
                                initialValue: defaultEmergencyContactNo,
                                rules: [{}]
                              })(<Input placeholder={intl.get('booking_form.guestDetails.emergencyContactNo').d('Emergency Contact Number')} />)}
                            </FormItem>
                          </Col>
                        </Row>
                      </Card>
                      <Card
                        className="reservation-card"
                        title={intl.get('booking_form.guestDetails.vehicleDetails').d('Vehicle Details')}
                        loading={isLoading}
                      >
                        {this.renderVehicle()}
                        <FormItem className="guest-input">
                          <Button type="dashed" onClick={this.addVehicle} style={{ width: '100%' }}>
                            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addVehicle').d('Add vehicle')}
                          </Button>
                        </FormItem>
                      </Card>
                      <Card
                        className="reservation-card"
                        title={intl.get('booking_form.guestDetails.itemsProvided').d('Items Provided')}
                        loading={isLoading}
                      >
                        {this.renderItemsProvided()}
                        <FormItem className="guest-input">
                          <Button type="dashed" onClick={this.addItemsProvided} style={{ width: '100%' }}>
                            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addItem').d('Add item')}
                          </Button>
                        </FormItem>
                      </Card>
                      <ActionButtons
                        shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                        isSaveButtonLoading={isSaveButtonLoading}
                        isDeleteButtonLoading={isDeleteButtonLoading}
                        onDelete={this.handleDelete}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        className="rf-buttons-center"
                      />
                    </Col>
                    <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
                      <TransactionDetails
                        isLoading={isLoading}
                        reservation={reservationDetails}
                        checkInDate={this.getCheckInDate()}
                        checkOutDate={this.getCheckOutDate()}
                        noOfNight={this.getNoOfBookingNights()}
                        totalCharges={totalCharges}
                        originalTotalCharges={originalTotalCharges}
                        outstandingBalance={outstandingBalance}
                        depositCollectedForTrxn={depositCollectedForTrxn}
                        onDepositChange={this.handleOnDepositFieldChange}
                        depositCollectedForTrxnErrMsg={depositCollectedForTrxnErrMsg}
                        onCollectBtnClick={this.handleOnClickedCollectDepositBtn}
                        onRefundBtnClick={this.handleOnClickedRefundDepositBtn}
                        onDeductBtnClick={this.handleOnClickedDeductDepositBtn}
                        pfdCardModalVisibilityToggle={this.pfdCardModalVisibilityToggle}
                        showPrice={this.authorizedItem([permissionConstants.TRANSACTION_VIEW])}
                        currency={this.state.currency}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        host={defaultHost}
                      />
                    </Col>
                  </Row>
                  <ActionButtons
                    shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                    isSaveButtonLoading={isSaveButtonLoading}
                    isDeleteButtonLoading={isDeleteButtonLoading}
                    onDelete={this.handleDelete}
                    checkIsAdminReadOnly={checkIsAdminReadOnly}
                    className="rf-buttons-bottom"
                  />
                </Form>
              </TabPane>
            )}
            {(query.selectedTab === 'transactions' ||
              (this.checkIsCommercialReservation() && this.authorizedItem([permissionConstants.TRANSACTION_VIEW]))) && (
              <TabPane
                tab={intl.get('reservations.transactions.headerLabels.transactions').d('Transactions')}
                key="transactions"
                forceRender={true}
                style={{ padding: '0 16px' }}
              >
                <Form onSubmit={this.handleSubmit} layout="horizontal">
                  <Row gutter={16}>
                    <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
                      <TransactionTab
                        reservationId={id}
                        outstandingAmounts={reservationDetails.outstandingAmounts}
                        transactions={reservationDetails.transactions}
                        currency={this.state.currency}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                      />
                      <ActionButtons
                        shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                        isSaveButtonLoading={isSaveButtonLoading}
                        isDeleteButtonLoading={isDeleteButtonLoading}
                        onDelete={this.handleDelete}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        className="rf-buttons-center"
                      />
                    </Col>
                    <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
                      <TransactionDetails
                        isLoading={isLoading}
                        reservation={reservationDetails}
                        checkInDate={this.getCheckInDate()}
                        checkOutDate={this.getCheckOutDate()}
                        noOfNight={this.getNoOfBookingNights()}
                        totalCharges={totalCharges}
                        originalTotalCharges={originalTotalCharges}
                        outstandingBalance={outstandingBalance}
                        depositCollectedForTrxn={depositCollectedForTrxn}
                        onDepositChange={this.handleOnDepositFieldChange}
                        depositCollectedForTrxnErrMsg={depositCollectedForTrxnErrMsg}
                        onCollectBtnClick={this.handleOnClickedCollectDepositBtn}
                        onRefundBtnClick={this.handleOnClickedRefundDepositBtn}
                        onDeductBtnClick={this.handleOnClickedDeductDepositBtn}
                        pfdCardModalVisibilityToggle={this.pfdCardModalVisibilityToggle}
                        showPrice={this.authorizedItem([permissionConstants.TRANSACTION_VIEW])}
                        currency={this.state.currency}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        host={defaultHost}
                      />
                    </Col>
                  </Row>
                  <ActionButtons
                    shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                    isSaveButtonLoading={isSaveButtonLoading}
                    isDeleteButtonLoading={isDeleteButtonLoading}
                    onDelete={this.handleDelete}
                    checkIsAdminReadOnly={checkIsAdminReadOnly}
                    className="rf-buttons-bottom"
                  />
                </Form>
              </TabPane>
            )}
            {(query.selectedTab === 'addon' || this.checkIsCommercialReservation()) && defaultAddOns.length > 0 && (
              <TabPane
                tab={intl.get('reservations.transactions.headerLabels.addOns').d('Add-ons')}
                key="addons"
                forceRender={true}
                style={{ padding: '0 16px' }}
              >
                <Form onSubmit={this.handleOnTrxnUpdateSubmit} layout="horizontal">
                  <Row gutter={16}>
                    <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
                      {this.renderAddOns()}
                      <ActionButtons
                        shouldShowDeleteButton={false}
                        isSaveButtonLoading={isSaveButtonLoading}
                        className="rf-buttons-center"
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                      />
                    </Col>
                    <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
                      <TransactionDetails
                        isLoading={isLoading}
                        reservation={reservationDetails}
                        checkInDate={this.getCheckInDate()}
                        checkOutDate={this.getCheckOutDate()}
                        noOfNight={this.getNoOfBookingNights()}
                        totalCharges={totalCharges}
                        originalTotalCharges={originalTotalCharges}
                        outstandingBalance={outstandingBalance}
                        depositCollectedForTrxn={depositCollectedForTrxn}
                        onDepositChange={this.handleOnDepositFieldChange}
                        depositCollectedForTrxnErrMsg={depositCollectedForTrxnErrMsg}
                        onCollectBtnClick={this.handleOnClickedCollectDepositBtn}
                        onRefundBtnClick={this.handleOnClickedRefundDepositBtn}
                        onDeductBtnClick={this.handleOnClickedDeductDepositBtn}
                        pfdCardModalVisibilityToggle={this.pfdCardModalVisibilityToggle}
                        showPrice={this.authorizedItem([permissionConstants.TRANSACTION_VIEW])}
                        currency={this.state.currency}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        host={defaultHost}
                      />
                    </Col>
                  </Row>
                  <ActionButtons
                    shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                    isSaveButtonLoading={isSaveButtonLoading}
                    isDeleteButtonLoading={isDeleteButtonLoading}
                    onDelete={this.handleDelete}
                    checkIsAdminReadOnly={checkIsAdminReadOnly}
                    className="rf-buttons-bottom"
                  />
                </Form>
              </TabPane>
            )}
            {(query.selectedTab === 'reviews' || this.checkIsCommercialReservation()) && airbnbReviews.length > 0 && (
              <TabPane
                tab={
                  <span>
                    <Avatar size="small" src={airbnbLogo} style={{ marginBottom: 3, marginRight: 5 }} />
                    {'Airbnb Reviews'}
                  </span>
                }
                key="reviews"
                forceRender={true}
                style={{ padding: '0 16px' }}
              >
                <Form onSubmit={this.handleOnAirbnbReviewSubmit} layout="horizontal">
                  <Row gutter={16}>
                    <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
                      {this.renderAirbnbReviews(this.ratingCategoryVisibilityToggle, this.toggleReplyReview)}
                      {(airbnbReviews.filter(review => review.reviewer_role === 'host')[0].submitted === false || toggleReply === true) && (
                        <ActionButtons
                          shouldShowDeleteButton={false}
                          isSaveButtonLoading={isSaveButtonLoading}
                          className="rf-buttons-center"
                          checkIsAdminReadOnly={checkIsAdminReadOnly}
                        />
                      )}
                    </Col>
                    <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
                      <TransactionDetails
                        isLoading={isLoading}
                        reservation={reservationDetails}
                        checkInDate={this.getCheckInDate()}
                        checkOutDate={this.getCheckOutDate()}
                        noOfNight={this.getNoOfBookingNights()}
                        totalCharges={totalCharges}
                        originalTotalCharges={originalTotalCharges}
                        outstandingBalance={outstandingBalance}
                        depositCollectedForTrxn={depositCollectedForTrxn}
                        onDepositChange={this.handleOnDepositFieldChange}
                        depositCollectedForTrxnErrMsg={depositCollectedForTrxnErrMsg}
                        onCollectBtnClick={this.handleOnClickedCollectDepositBtn}
                        onRefundBtnClick={this.handleOnClickedRefundDepositBtn}
                        onDeductBtnClick={this.handleOnClickedDeductDepositBtn}
                        pfdCardModalVisibilityToggle={this.pfdCardModalVisibilityToggle}
                        showPrice={this.authorizedItem([permissionConstants.TRANSACTION_VIEW])}
                        checkIsAdminReadOnly={checkIsAdminReadOnly}
                        host={defaultHost}
                      />
                    </Col>
                  </Row>
                  <ActionButtons
                    shouldShowDeleteButton={checkIsAllowDeleteReservation()}
                    isSaveButtonLoading={isSaveButtonLoading}
                    isDeleteButtonLoading={isDeleteButtonLoading}
                    onDelete={this.handleDelete}
                    checkIsAdminReadOnly={checkIsAdminReadOnly}
                    className="rf-buttons-bottom"
                  />
                </Form>
              </TabPane>
            )}
            {(query.selectedTab === 'activityLogs' || this.authorizedItem([permissionConstants.ACTIVITY_LOG_VIEW])) && (
              <TabPane
                tab={intl.get('reservations.activityLogs.headerLabels.activityLogs').d('Activity Logs')}
                key="activityLogs"
                style={{ padding: '0 16px' }}
                forceRender={true}
              >
                <div>
                  <Timeline>
                    {activityLog.map((log, idx) => (
                      <Timeline.Item key={log._id}>
                        <Descriptions
                          title={intl
                            .get(`reservations.activityLogs.headerLabels.${this.capitalizeFirstLetter(log.method)}`)
                            .d(this.capitalizeFirstLetter(log.method))}
                          size="small"
                          bordered
                          colon={false}
                        >
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: 600 }}>
                                {idx === 0
                                  ? intl.get('reservations.activityLogs.headerLabels.createdAt').d('Created At')
                                  : intl.get('reservations.activityLogs.headerLabels.updatedAt').d('Updated At')}
                              </span>
                            }
                          >
                            {moment(log.createdAt).format('DD-MMM-YYYY HH:mm')}
                          </Descriptions.Item>
                          {this.checkDifferences(log, idx, 'creator')}
                          {this.checkDifferences(log, idx, 'roomType')}
                          {this.checkDifferences(log, idx, 'unit')}
                          {this.checkDifferences(log, idx, 'checkInDate')}
                          {this.checkDifferences(log, idx, 'checkOutDate')}
                          {this.checkDifferences(log, idx, 'clashing')}
                          {this.checkDifferences(log, idx, 'status')}
                        </Descriptions>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </TabPane>
            )}
          </Tabs>
        </Row>
        {reservationDetails._id && (
          <PdfCardModal
            reservationId={reservationDetails._id}
            visible={pfdCardModalVisible}
            handleClose={this.pfdCardModalVisibilityToggle}
            currency={this.state.currency}
            host={defaultHost}
            checkIsAdmin={checkIsAdmin}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(withAppContext(Form.create()(ReservationForm)));
