import AirbnbLogo from 'images/airbnb-logo.png';
import AgodaLogo from 'images/agoda-logo-icon.png';
import AgodaHomesLogo from 'images/agodahomes-logo-icon.png';
import BookingComLogo from 'images/booking-logo-icon.png';
import CtripLogo from 'images/ctrip-logo-icon.png';
import CtripCMLogo from 'images/ctrip-logo-icon.png';
import ExpediaLogo from 'images/expedia-logo-icon.png';
import HomeAwayLogo from 'images/homeaway-logo-icon.png';
import TravelokaLogo from 'images/traveloka-logo-icon.png';
import TiketcomLogo from 'images/tiketcom-logo-icon.png';
import OthersLogo from 'images/others-logo-icon.png';
import HostPlatformLogo from 'images/hostplatform-logo-icon.png';
import BELogo from 'images/HBE-BE-logo-2.png';
import ExtIntegrationLogo from 'images/extIntegration-logo-icon.png';
import usFlag from '../images/us-flag.png';
import chFlag from '../images/china-flag.png';
import thFlag from '../images/thai-flag.png';

/* ------------------------------general constants------------------------------*/
// ------------------------------date
// Date format
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITH_WEEKDAY_FORMAT = 'YYYY-MM-DD, dddd';
export const FORMAL_DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = `YYYY-MM`;
export const MONTH_YEAR_DISPLAY_FORMAT = 'MMMM YYYY';
export const DATE_DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;

export const MONTHS = [
  { label: 'January', code: 0 },
  { label: 'February', code: 1 },
  { label: 'March', code: 2 },
  { label: 'April', code: 3 },
  { label: 'May', code: 4 },
  { label: 'June', code: 5 },
  { label: 'July', code: 6 },
  { label: 'August', code: 7 },
  { label: 'September', code: 8 },
  { label: 'October', code: 9 },
  { label: 'November', code: 10 },
  { label: 'December', code: 11 }
];

export const constructDateFormat = (separator = '-') => `YYYY${separator}MM${separator}DD`;

// ------------------------------regex
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_REGEX = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
export const REGEX_NOT_NUMBER_N_DOT = /[^0-9.]/g;
export const LETTER_N_NUMBER = '^[a-zA-Z0-9]*$';

// ------------------------------general
export const SEPARATOR_DASH = '-';
export const STANDARD_RATE_CODE = 'STD';

export const MIN_INTL_CONTACT_NO_LEN = 7;

export const BLANK_IMAGE = 'https:dashboard-operator-image.s3.amazonaws.com/3386bffc-75c9-4390-a670-fc11ed8febe1_blanksquare.png';

/* ------------------------------specific constants------------------------------*/
// Rate Distribution
export const RATE_DISTRIBUTION_TAB = 'Rate Distribution Tab';
export const RATE_DERIVATIVE = 'rateDerivative';
export const WEEKDAY_RATE = 'weekdayRate';
export const WEEKEND_RATE = 'weekendRate';
export const PAX_THRESHOLD = 'noOfPaxThreshold';
export const EXTRA_PAX_FEE = 'feePerExtraPax';
export const RATE_DERIVATIVES = [
  { code: 'independent', value: 'Independent', key: 'independent', isDerived: false, isDefault: true },
  { code: 'derived', value: 'Derived from Web', key: 'derived', isDerived: true, isDefault: false }
];

// Pricing
export const PRICING_TAB = 'Pricing Tab';
export const CLEANING_FEE = 'Cleaning Fee';
export const CHARGE_TYPE = 'Charge Type';

// Rate Modifier
export const MODIFIER_TYPE = 'modifierType';
export const MODIFIER_SIGN = 'modifierSign';
export const MODIFIER_AMOUNT = 'modifierAmount';

// Booking Status
export const BOOKINGTYPE_MAINTENANCE = 5;
export const BOOKINGTYPE_BLOCK = 6;

// OTAs
export const OTAS = [
  { code: 'agoda', label: 'Agoda', logo: AgodaLogo },
  { code: 'agodahomes', label: 'Agoda Homes', logo: AgodaHomesLogo },
  { code: 'airbnb', label: 'Airbnb', logo: AirbnbLogo, isAirbnb: true },
  { code: 'bookingcom', label: 'Booking.com', logo: BookingComLogo },
  { code: 'ctrip', label: 'Ctrip', logo: CtripLogo },
  { code: 'ctripcm', label: 'CtripCM', logo: CtripCMLogo },
  { code: 'expedia', label: 'Expedia', logo: ExpediaLogo },
  { code: 'homeaway', label: 'HomeAway', logo: HomeAwayLogo },
  { code: 'traveloka', label: 'Traveloka', logo: TravelokaLogo },
  { code: 'tiketcom', label: 'Tiket.com', logo: TiketcomLogo },
  { code: 'hostplatform', label: 'HostPlatform', logo: HostPlatformLogo },
  { code: 'bookingEngine', label: 'Homes Booking Website', logo: BELogo },
  { code: 'hotelBookingEngine', label: 'Hotel Booking Website', logo: BELogo },
  { code: 'extIntegration', label: 'ExtIntegration', logo: ExtIntegrationLogo },
  { code: 'others', label: 'Others', logo: OthersLogo, isDefault: true }
];

// OTA Form
export const INTEGRATION_TYPES = [
  { code: 'listing', tabTitle: 'Listing Integration', isListingIntegration: true },
  { code: 'hotel', tabTitle: 'Hotel Integration' }
];

// User Profile
export const DEFAULT_USER_PROFILE_URL = 'https://dashboard-operator-image.s3.amazonaws.com/e1b4d0a0-3114-4122-85ff-ef0f54022485_notfound.png';

// Fine Print for Invoice, Guest Registration Card, Quotation
// Note: If you're changing this, please change in titan too.
export const DEFAULT_FINE_PRINT =
  'NOTICE TO GUESTS: This property is privately owned and the management reserves the right to refuse service to anyone. Management will not be responsible for accidents or injury to guests or for loss of money, jewelry or valuables of any kind. Management will not be responsible for any item left in the room. \n CHECK OUT TIME : 12:00 PM SELF REGISTRATION ONLY. \n I AGREE that my liability for this bill is not waived and agree to be held personally liable in the event that the indicated person or company failed to pay for any part or full amount of these charges including any missing/damages items. I agree that if an attorney is retained to collect these charges, I will pay all reasonable attorney’s fees and costs incurred. If payment is by credit card you are authorized to charge my account for all charges incurred, including any and all damages/missing items, etc. I agree that the sole purpose of renting this room is for my own residency only.';

export const acceptedUserList = [
  '621da15492136241fa97c37d', // Eunice admin
  '5bc40e3561f43c781215c981', // Jayden
  '61d011ebcfa2731ae09444b2' // Benny
  // '61a0a826cfa2731ae04bf3e3' // Johnson
];

export const SuperAdminList = [
  '621da15492136241fa97c37d', // Eunice admin
  '5bc40e3561f43c781215c981', // Jayden
  '61d011ebcfa2731ae09444b2', // Benny
  '61a0a826cfa2731ae04bf3e3', // Johnson
  '631969b4c560f80f72b553c2' // Harris
];

export const AIRBNB_REVIEW_CATEGORY = [
  { tag: 'host_review_guest_positive_neat_and_tidy', category: 'cleanliness', description: 'Neat & tidy' },
  { tag: 'host_review_guest_positive_kept_in_good_condition', category: 'cleanliness', description: 'Kept in good condition' },
  { tag: 'host_review_guest_positive_took_care_of_garbage', category: 'cleanliness', description: 'Took care of garbage' },
  { tag: 'host_review_guest_negative_ignored_checkout_directions', category: 'cleanliness', description: 'Ignored check-out directions' },
  { tag: 'host_review_guest_negative_garbage', category: 'cleanliness', description: 'Excessive garbage' },
  { tag: 'host_review_guest_negative_messy_kitchen', category: 'cleanliness', description: 'Messy kitchen' },
  { tag: 'host_review_guest_negative_damage', category: 'cleanliness', description: 'Damaged property' },
  { tag: 'host_review_guest_negative_ruined_bed_linens', category: 'cleanliness', description: 'Ruined bed linens' },
  { tag: 'host_review_guest_negative_arrived_early', category: 'respect_house_rules', description: 'Arrived too early' },
  { tag: 'host_review_guest_negative_stayed_past_checkout', category: 'respect_house_rules', description: 'Stayed past checkout' },
  { tag: 'host_review_guest_negative_unapproved_guests', category: 'respect_house_rules', description: 'Unapproved guests' },
  { tag: 'host_review_guest_negative_unapproved_pet', category: 'respect_house_rules', description: 'Unapproved pet' },
  { tag: 'host_review_guest_negative_did_not_respect_quiet_hours', category: 'respect_house_rules', description: 'Didn’t respect quiet hours' },
  { tag: 'host_review_guest_negative_unapproved_filming	', category: 'respect_house_rules', description: 'Unapproved filming or photography' },
  { tag: 'host_review_guest_negative_unapproved_event', category: 'respect_house_rules', description: 'Unapproved event' },
  { tag: 'host_review_guest_negative_smoking', category: 'respect_house_rules', description: 'Smoking' },
  { tag: 'host_review_guest_positive_helpful_messages', category: 'communication', description: 'Helpful messages' },
  { tag: 'host_review_guest_positive_respectful', category: 'communication', description: 'Respectful' },
  { tag: 'host_review_guest_positive_always_responded', category: 'communication', description: 'Always responded' },
  { tag: 'host_review_guest_negative_unhelpful_messages', category: 'communication', description: 'Unhelpful responses' },
  { tag: 'host_review_guest_negative_disrespectful', category: 'communication', description: 'Disrespectful' },
  { tag: 'host_review_guest_negative_unreachable', category: 'communication', description: 'Unreachable' },
  { tag: 'host_review_guest_negative_slow_responses', category: 'communication', description: 'Slow responses' }
];

export const AIRBNB_GUEST_REVIEW_CATEGORY = [
  { tag: 'guest_review_host_positive_looked_like_photos', category: 'accuracy', description: 'Looked like the photos' },
  { tag: 'guest_review_host_positive_matched_description', category: 'accuracy', description: 'Matched the description' },
  { tag: 'guest_review_host_positive_had_listed_amenities_and_services', category: 'accuracy', description: 'Had listed amenities & services' },
  { tag: 'guest_review_host_negative_smaller_than_expected', category: 'accuracy', description: 'Smaller than expected' },
  { tag: 'guest_review_host_negative_did_not_match_photos', category: 'accuracy', description: 'Didn’t match the photos' },
  { tag: 'guest_review_host_negative_needs_maintenance', category: 'accuracy', description: 'Needs maintenance' },
  { tag: 'guest_review_host_negative_unexpected_fees', category: 'accuracy', description: 'Unexpected fees' },
  { tag: 'guest_review_host_negative_excessive_rules', category: 'accuracy', description: 'Excessive rules' },
  { tag: 'guest_review_host_negative_unexpected_noise', category: 'accuracy', description: 'Unexpected noise' },
  { tag: 'guest_review_host_negative_inaccurate_location', category: 'accuracy', description: 'Inaccurate location' },
  { tag: 'guest_review_host_negative_missing_amenity', category: 'accuracy', description: 'Missing amenity or service' },
  { tag: 'guest_review_host_negative_broken_or_missing_lock', category: 'accuracy', description: 'Broken or missing lock on door' },
  { tag: 'guest_review_host_negative_unexpected_guests	', category: 'accuracy', description: 'Unexpected guest(s) in space' },
  { tag: 'guest_review_host_negative_incorrect_bathroom', category: 'accuracy', description: 'Incorrect bathroom type' },
  { tag: 'guest_review_host_positive_responsive_host', category: 'checkin', description: 'Responsive host' },
  { tag: 'guest_review_host_positive_clear_instructions', category: 'checkin', description: 'Clear instructions' },
  { tag: 'guest_review_host_positive_easy_to_find	', category: 'checkin', description: 'Easy to find' },
  { tag: 'guest_review_host_positive_easy_to_get_inside', category: 'checkin', description: 'Easy to get inside' },
  { tag: 'guest_review_host_positive_flexible_check_in', category: 'checkin', description: 'Flexible check-in' },
  { tag: 'guest_review_host_negative_hard_to_locate', category: 'checkin', description: 'Hard to locate' },
  { tag: 'guest_review_host_negative_unclear_instructions', category: 'checkin', description: 'Unclear instructions' },
  { tag: 'guest_review_host_negative_trouble_with_lock', category: 'checkin', description: 'Trouble with lock' },
  { tag: 'guest_review_host_negative_unresponsive_host', category: 'checkin', description: 'Unresponsive Host' },
  { tag: 'guest_review_host_negative_had_to_wait', category: 'checkin', description: 'Had to wait' },
  { tag: 'guest_review_host_negative_hard_to_get_inside', category: 'checkin', description: 'Hard to get inside' },
  { tag: 'guest_review_host_positive_felt_at_home', category: 'checkin', description: 'Felt right at home' },
  { tag: 'guest_review_host_positive_spotless_furniture_and_linens', category: 'cleanliness', description: 'Spotless furniture & linens' },
  { tag: 'guest_review_host_positive_free_of_clutter', category: 'cleanliness', description: 'Free of clutter' },
  { tag: 'guest_review_host_positive_squeaky_clean_bathroom	', category: 'cleanliness', description: 'Squeaky-clean bathroom' },
  { tag: 'guest_review_host_positive_pristine_kitchen', category: 'cleanliness', description: 'Pristine kitchen' },
  { tag: 'guest_review_host_negative_dirty_or_dusty', category: 'cleanliness', description: 'Dirty or dusty' },
  { tag: 'guest_review_host_negative_noticeable_smell', category: 'cleanliness', description: 'Noticeable smell' },
  { tag: 'guest_review_host_negative_stains', category: 'cleanliness', description: 'Stains' },
  { tag: 'guest_review_host_negative_excessive_clutter', category: 'cleanliness', description: 'Excessive clutter' },
  { tag: 'guest_review_host_negative_messy_kitchen', category: 'cleanliness', description: 'Messy kitchen' },
  { tag: 'guest_review_host_negative_hair_or_pet_hair', category: 'cleanliness', description: 'Hair or pet hair' },
  { tag: 'guest_review_host_negative_dirty_bathroom', category: 'cleanliness', description: 'Dirty bathroom' },
  { tag: 'guest_review_host_negative_trash_left_behind', category: 'cleanliness', description: 'Trash left behind' },
  { tag: 'guest_review_host_positive_always_responsive', category: 'communication', description: 'Always responsive' },
  { tag: 'guest_review_host_positive_local_recommendations	', category: 'communication', description: 'Local recommendations' },
  { tag: 'guest_review_host_positive_proactive', category: 'communication', description: 'Proactive' },
  { tag: 'guest_review_host_positive_helpful_instructions', category: 'communication', description: 'Helpful instructions' },
  { tag: 'guest_review_host_positive_considerate', category: 'communication', description: 'Considerate' },
  { tag: 'guest_review_host_negative_slow_to_respond', category: 'communication', description: 'Slow to respond' },
  { tag: 'guest_review_host_negative_not_helpful', category: 'communication', description: 'Not helpful' },
  { tag: 'guest_review_host_negative_missing_house_instructions', category: 'communication', description: 'Missing house instructions' },
  { tag: 'guest_review_host_negative_unclear_checkout_tasks', category: 'communication', description: 'Unclear checkout tasks' },
  { tag: 'guest_review_host_negative_inconsiderate', category: 'communication', description: 'Inconsiderate' },
  { tag: 'guest_review_host_negative_excessive_checkout_tasks', category: 'communication', description: 'Excessive checkout tasks' },
  { tag: 'guest_review_host_positive_peaceful', category: 'location', description: 'Peaceful' },
  { tag: 'guest_review_host_positive_beautiful_surroundings	', category: 'location', description: 'Beautiful surroundings' },
  { tag: 'guest_review_host_positive_private', category: 'location', description: 'Private' },
  { tag: 'guest_review_host_positive_great_restaurants', category: 'location', description: 'Great restaurants' },
  { tag: 'guest_review_host_positive_lots_to_do', category: 'location', description: 'Lots to do' },
  { tag: 'guest_review_host_positive_walkable', category: 'location', description: 'Walkable' },
  { tag: 'guest_review_host_negative_noisy', category: 'location', description: 'Noisy' },
  { tag: 'guest_review_host_negative_not_much_to_do', category: 'location', description: 'Not much to do' },
  { tag: 'guest_review_host_negative_bland_surroundings', category: 'location', description: 'Bland surroundings' },
  { tag: 'guest_review_host_negative_not_private', category: 'location', description: 'Not private' },
  { tag: 'guest_review_host_negative_inconvenient_location', category: 'location', description: 'Inconvenient location' }
];

export const languageOptions = [
  { key: 'en-US', label: 'English', flagUrl: usFlag, value: 'en-US' },
  { key: 'zh-CN', label: '简体中文', flagUrl: chFlag, value: 'zh-CN' },
  { key: 'th-TH', label: 'ภาษาไทย', flagUrl: thFlag, value: 'th-TH' }
];
// Add more languages as needed
export const listingDeletionReasons = [
  { label: 'Returned to owner', key: 'Returned to owner', value: 0 },
  { label: 'Convert to long stay', key: 'Convert to long stay', value: 1 },
  { label: 'Restructure', key: 'Restructure', value: 2 },
  { label: 'Venture to others business', key: 'Venture to others business', value: 3 }
];

// Special Host IDs
export const SPECIAL_HOST_IDS = ['67b834191b208904d0b94070', '67a0205060c37704799b183d', '67f676bee83b745d0454480f'];
