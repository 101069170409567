import React, { Fragment } from 'react';
import moment from 'moment';
import { Alert, Table, Row, Col, Card, Divider, Icon, Skeleton, Modal, Button, Form, DatePicker, message } from 'antd';

import { withAppContext } from 'context/AppContext';

import {
  getChargesTypesConstant,
  getDepositTypesConstant,
  getTaxTypesConstant,
  getTransactionTypesConstant,
  getPaymentMethodsConstant
} from 'utils/apis/constants';
import { putUpdateTrxn, deleteTrxn } from 'utils/apis/transaction';
import { convertVariableNameToReadableString, generateDisplayFee, numberWithCommas } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import styles from './TransactionDetailsTable.module.css';
import './TransactionDetailsTable.css';

import intl from 'react-intl-universal';

const FormItem = Form.Item;

const FooterSubItem = ({ label, amount, currency = 'RM' }) => {
  return (
    <Row gutter={16} style={{ color: '#474747' }}>
      <Col span={13}>
        <p className={styles.rightText}>{label}: </p>
      </Col>
      <Col span={1} />
      <Col span={5}>
        <p className={styles.rightText}>
          {amount.toFixed(2)} {currency}
        </p>
      </Col>
      <Col span={5} />
    </Row>
  );
};

const Footer = ({ summary, currency = 'RM' }) => (
  <Fragment>
    <Row gutter={16}>
      <Col span={13}>
        <h3 className={styles.rightText}>{intl.get('reservations.transactions.headerLabels.paymentReceived').d('Payment Received: ')}</h3>
      </Col>
      <Col span={1} />
      <Col span={5}>
        <h3 className={styles.rightText}>
          {summary.paymentReceived.toFixed(2)} {currency}
        </h3>
      </Col>
      <Col span={5} />
    </Row>

    <FooterSubItem label={intl.get('reservations.transactions.headerLabels.charges').d('Charges: ')} amount={summary.charges} currency={currency} />
    {summary.taxes && summary.taxes.sst && (
      <FooterSubItem
        label={intl.get('reservations.transactions.chargesTypeAndTaxType.Sales & Service Tax').d('Sales & Service Tax')}
        amount={summary.taxes.sst}
        currency={currency}
      />
    )}
    {summary.taxes && summary.taxes.tourism && (
      <FooterSubItem
        label={intl.get('reservations.transactions.chargesTypeAndTaxType.Tourism Tax').d('Tourism Tax')}
        amount={summary.taxes.tourism}
        currency={currency}
      />
    )}
    {summary.taxes && summary.taxes.heritage && (
      <FooterSubItem
        label={intl.get('reservations.transactions.chargesTypeAndTaxType.Heritage Tax').d('Heritage Tax')}
        amount={summary.taxes.heritage}
        currency={currency}
      />
    )}
    {summary.taxes && summary.taxes.otherTax && (
      <FooterSubItem
        label={intl.get('reservations.transactions.chargesTypeAndTaxType.Other Tax').d('Other Tax')}
        amount={summary.taxes.otherTax}
        currency={currency}
      />
    )}
    {summary.taxes && summary.taxes.ota && (
      <FooterSubItem
        label={intl.get('reservations.transactions.chargesTypeAndTaxType.OTA Tax').d('OTA Tax')}
        amount={summary.taxes.ota}
        currency={currency}
      />
    )}
    <FooterSubItem
      label={intl.get('booking_form.paymentDetailsLabels.depositCollected').d('Deposit Collected')}
      amount={summary.depositCollected}
      currency={currency}
    />
    <FooterSubItem
      label={intl.get('booking_form.paymentDetailsLabels.depositRefunded').d('Deposit Refunded')}
      amount={summary.depositRefunded}
      currency={currency}
    />

    <Row gutter={16} style={{ borderBottom: '3px double #474747', borderTop: '1px solid #474747', padding: '6px 0px' }}>
      <Col span={13}>
        <h3 className={styles.rightText}>{intl.get('reservations.transactions.headerLabels.balance').d('Balance: ')}</h3>
      </Col>
      <Col span={1} />
      <Col span={5}>
        <h3 className={styles.rightText} style={summary.balance >= 0 ? { color: 'green' } : { color: 'red' }}>
          {summary.balance.toFixed(2)} {currency}
        </h3>
      </Col>
      <Col span={5} />
    </Row>
  </Fragment>
);

class TransactionModal extends React.Component {
  render() {
    const { form, record, handleOnTrxnUpdateSubmit, currency } = this.props;
    return (
      <Form onSubmit={event => handleOnTrxnUpdateSubmit(event, form)} style={{ width: '100%' }}>
        <Row>
          <h4>
            {`${intl.get('reservations.transactions.headerLabels.transactionType').d('Transaction Type')} : 
  ${intl
    .get(`reservations.transactions.chargesType.${convertVariableNameToReadableString(record.type)}`)
    .d(convertVariableNameToReadableString(record.type))} - 
  ${
    record.subType === 'sst'
      ? intl.get('reservations.transactions.chargesTypeAndTaxType.Sales & Service').d('Sales & Service')
      : intl
          .get(`reservations.transactions.chargesTypeAndTaxType.${convertVariableNameToReadableString(record.subType)}`)
          .d(convertVariableNameToReadableString(record.subType))
  }
`}
          </h4>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <p style={{ marginBottom: '8px' }}>{intl.get('reservations.transactions.headerLabels.date').d('Date')}</p>
            <FormItem style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('trxnDate', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.transactions.placeholder.dateAlert').d('Please select a date!')
                  }
                ],
                initialValue: moment(record.date, 'YYYY-MM-DD')
              })(<DatePicker size="large" style={{ width: '100%', marginBottom: '16px' }} format={'YYYY-MM-DD'} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormInputNumber
              form={form}
              precision={2}
              name="amount"
              minValue={1}
              defaultValue={record.amount}
              label={`${intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')} (${currency})`}
              placeholder="Amount"
              size="large"
            />
          </Col>
        </Row>
        <Row>
          <FormInput
            inputType="textarea"
            label={intl.get('reservations.transactions.headerLabels.remarks').d('Remarks (optional)')}
            name="remarks"
            defaultValue={record.remarks}
            placeholder={intl.get('reservations.transactions.placeholder.remarks').d('Provide your remark if you have any')}
            form={form}
            extraProps={{ rows: 2 }}
            size="large"
          />
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Button type="primary" htmlType="submit" size="large" style={{ width: '70%' }}>
            {intl.get('reservations.headerLabels.save').d('Save')}
          </Button>
        </Row>
      </Form>
    );
  }
}
const TransactionModalForm = Form.create()(TransactionModal);

const DeleteModal = ({ record, currency = 'RM' }) => (
  <div>
    <Alert
      message={intl.get('reservations.transactions.deletion.warning').d('Warning')}
      description={intl.get('reservations.transactions.deletion.desc').d('You will not be able to undo this action.')}
      type="warning"
      showIcon
    />
    <Card
      title={`${intl.get('reservations.transactions.headerLabels.transactions').d('Transactions')}: ${
        record.subType === 'sst'
          ? intl.get('reservations.transactions.chargesTypeAndTaxType.Sales & Service').d('Sales & Service')
          : intl
              .get(`reservations.transactions.chargesTypeAndTaxType.${convertVariableNameToReadableString(record.subType)}`)
              .d(convertVariableNameToReadableString(record.subType))
      }  ${intl
        .get(`reservations.transactions.chargesType.${convertVariableNameToReadableString(record.type)}`)
        .d(convertVariableNameToReadableString(record.type))}`}
    >
      <p>
        <b>{intl.get('reservations.transactions.deletion.type').d('Type')}: </b>
        {intl
          .get(`reservations.transactions.chargesType.${convertVariableNameToReadableString(record.type)}`)
          .d(convertVariableNameToReadableString(record.type))}
      </p>
      <p>
        <b>
          {intl
            .get(`reservations.transactions.chargesType.${convertVariableNameToReadableString(record.type)}`)
            .d(convertVariableNameToReadableString(record.type))}{' '}
          {intl.get('reservations.transactions.deletion.taxFor').d('For')}:{' '}
        </b>
        {record.subType === 'sst'
          ? intl.get('reservations.transactions.chargesTypeAndTaxType.Sales & Service').d('Sales & Service')
          : intl
              .get(`reservations.transactions.chargesTypeAndTaxType.${convertVariableNameToReadableString(record.subType)}`)
              .d(convertVariableNameToReadableString(record.subType))}
      </p>
      <p>
        <b>{intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')}: </b>
        {generateDisplayFee(record.amount, true, null, currency)}
      </p>
      <p>
        <b>{intl.get('reservations.transactions.deletion.remarks').d('Remarks')}: </b>
        {record.remarks ? record.remarks : '-'}
      </p>
    </Card>
  </div>
);

class TransactionDailyBreakdownModal extends React.Component {
  handleCalculateSum = () => {
    const { form } = this.props;
    const breakdownFieldsValue = form.getFieldsValue();

    const newBreakdownSum = Object.values(breakdownFieldsValue).reduce((accumulator, currentValue) => {
      if (!currentValue.amount) {
        currentValue.amount = 0;
      }
      return accumulator + currentValue.amount;
    }, 0);

    return newBreakdownSum;
  };

  render() {
    const { form, record, handleOnDailyBreakdownUpdateSubmit, currency } = this.props;
    const newTotal = this.handleCalculateSum() || record.amount;

    return (
      <Form onSubmit={event => handleOnDailyBreakdownUpdateSubmit(event, form)} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={9} style={{ paddingLeft: '30px' }}>
            {intl.get('reservations.transactions.headerLabels.originalTotalAmount').d('Original Total Amount:')}
          </Col>
          <Col span={15} style={{ paddingLeft: '13px' }}>
            {currency} {numberWithCommas(parseFloat(record.originalRental))}
          </Col>
        </Row>
        <Row style={{ height: 20 }} />
        <Row gutter={16}>
          <Col span={9} style={{ paddingLeft: '30px' }}>
            {intl.get('reservations.transactions.headerLabels.newTotalAmount').d('New Total Amount:')}
          </Col>
          <Col span={15} style={{ paddingLeft: '13px' }}>
            {currency} {numberWithCommas(parseFloat(newTotal))}
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row gutter={16} className={styles.scrollableDiv}>
          {Object.keys(record.dailyBreakdown).map((keyName, i) => (
            <>
              <Col span={10} style={{ paddingLeft: '30px' }}>
                <p>{intl.get('reservations.transactions.headerLabels.date').d('Date')}</p>
                <p style={{ marginTop: '18px' }}>{keyName}</p>
              </Col>
              <Col span={12}>
                <FormInputNumber
                  form={form}
                  precision={2}
                  name={`${keyName}.amount`}
                  minValue={0}
                  defaultValue={record.dailyBreakdown && record.dailyBreakdown[keyName].amount}
                  label={intl.get('reservations.transactions.headerLabels.amount').d('Amount')}
                  placeholder={intl.get('reservations.transactions.placeholder.amount').d('Amount')}
                  size="large"
                  requiredErrorMessage={intl.get('servicePackage.message.amount').d('Please enter an amount')}
                  formatter={value => `${currency} ${value}`}
                  parser={value => value.replace(`${currency}`, '')}
                />
              </Col>
            </>
          ))}
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Button type="primary" htmlType="submit" size="large" className={styles.dailyBreakdownSaveBtn}>
            {intl.get('reservations.headerLabels.save').d('Save')}
          </Button>
        </Row>
      </Form>
    );
  }
}
const TransactionDailyBreakdownModalForm = Form.create()(TransactionDailyBreakdownModal);

class TransactionDetailsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedRecord: {},
      isEditClicked: false,
      isDeleteClicked: false,

      chargeTypes: [],
      depositTypes: [],
      taxTypes: [],

      depositTypesConstant: {},
      transactionTypesConstant: {},
      paymentMethodsconstant: {}
    };
  }

  componentDidMount() {
    this.getTransactionSubTypeConstants();
  }

  getTransactionSubTypeConstants = async () => {
    try {
      const chargeTypes = await getChargesTypesConstant().then(chargeTypes => {
        return Object.values(chargeTypes).map(chargeType => ({
          ...chargeType,
          value: chargeType['code'],
          label: chargeType['label']
        }));
      });

      const { depositTypesConstant, depositTypes } = await getDepositTypesConstant().then(depositTypesConstant => {
        const depositTypes = Object.values(depositTypesConstant).map(depositType => ({
          ...depositType,
          value: depositType['code'],
          label: depositType['label']
        }));

        return { depositTypesConstant, depositTypes };
      });

      const taxTypes = await getTaxTypesConstant().then(taxTypes => {
        return Object.values(taxTypes).map(taxType => ({
          ...taxType,
          value: taxType['code'],
          label: taxType['label']
        }));
      });

      const transactionTypesConstant = await getTransactionTypesConstant();

      const paymentMethodsconstant = await getPaymentMethodsConstant();

      this.setState({ chargeTypes, depositTypesConstant, depositTypes, taxTypes, transactionTypesConstant, paymentMethodsconstant });
    } catch (ex) {
      console.log(ex);
      this.setState({ chargeTypes: [], depositTypes: [], taxTypes: [] });
    }
  };

  getDailyBreakdown = (dailyBreakdown, originalDailyBreakdown) => {
    if (dailyBreakdown) {
      return Object.keys(dailyBreakdown).map(date => {
        if (originalDailyBreakdown) {
          return {
            date,
            amount: dailyBreakdown[date].amount,
            originalAmount: originalDailyBreakdown[date].amount
          };
        } else {
          return {
            date,
            amount: dailyBreakdown[date].amount
          };
        }
      });
    }

    return [];
  };

  checkIfDeductDeposit = transaction => {
    return transaction.remarks && transaction.remarks.toLowerCase().includes('deposit deducted');
  };

  getSummary = (transactions = []) => {
    const { depositTypesConstant, transactionTypesConstant } = this.state;

    let balance = 0;
    let paymentReceived = 0;
    let charges = 0;
    let taxes = {};
    let addOns = {};
    let depositCollected = 0;
    let depositRefunded = 0;
    let depositDeducted = 0;

    if (Object.keys(depositTypesConstant).length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        const transaction = transactions[i];
        if (transaction.type === transactionTypesConstant.PAYMENT.code && this.checkIfDeductDeposit(transaction)) {
          paymentReceived += transaction.amount;
          depositDeducted = transaction.amount;
          depositCollected -= transaction.amount;
        } else if (transaction.type === transactionTypesConstant.PAYMENT.code) {
          balance += transaction.amount;
          paymentReceived += transaction.amount;
        } else if (transaction.subType === depositTypesConstant.COLLECT.code) {
          balance += transaction.amount;
          depositCollected += transaction.amount;
        } else if (transaction.subType === depositTypesConstant.REFUND.code) {
          balance -= transaction.amount;
          depositRefunded = transaction.amount;
        } else if (transaction.type === transactionTypesConstant.TAX.code) {
          taxes[transaction.subType] = transaction.amount;
          balance -= transaction.amount;
        } else if (transaction.subType === 'stripeCharges') {
          balance -= 0;
          charges += 0;
        } else {
          // if (transaction.type === 'addon') {
          balance -= transaction.amount + (transaction.paymentCharges || 0);
          charges += transaction.amount + (transaction.paymentCharges || 0);
        }
        // else {
        //   balance -= transaction.amount;
        //   charges += transaction.amount;
        // }
      }
    }

    return {
      balance: balance - (depositRefunded > 0 ? 0 : depositCollected),
      paymentReceived,
      charges,
      taxes,
      addOns,
      depositCollected,
      depositRefunded,
      depositDeducted
    };
  };

  checkCanEdit = (subType, isEditable) => {
    const { checkIsAllowEditTransaction } = this.props;
    return isEditable && checkIsAllowEditTransaction();
  };

  checkCanDelete = (subType, isEditable) => {
    const { checkIsAllowDeleteTransaction } = this.props;
    return isEditable && checkIsAllowDeleteTransaction();
  };

  getPaymentMethodSuffix = method => {
    const { paymentMethodsconstant } = this.state;

    if (Object.keys(paymentMethodsconstant).length > 0) {
      if (method === paymentMethodsconstant.CASH.code) {
        return 'Paid in Cash';
      } else if (method === paymentMethodsconstant.CREDIT_CARD.code) {
        return 'Paid with Credit Card';
      } else if (method === paymentMethodsconstant.OTHERS.code) {
        return 'Paid with other method';
      }
    }

    return '';
  };

  getTransactionSubTypeLabel = (type, subType, payment) => {
    const { chargeTypes, depositTypes, taxTypes, transactionTypesConstant } = this.state;

    if (Object.keys(transactionTypesConstant).length === 0) {
      return;
    }

    const { label } =
      chargeTypes.find(chargeType => chargeType.code === subType) ||
      chargeTypes.find(chargeType => chargeType.code === type) ||
      taxTypes.find(taxType => taxType.code === subType) ||
      depositTypes.find(depositType => depositType.code === subType) ||
      {};

    if (type === transactionTypesConstant.PAYMENT.code) {
      const { method } = payment;

      const methodSuffix = this.getPaymentMethodSuffix(method);
      intl.get(`reservations.transactions.paymentMethod.${methodSuffix}`).d(methodSuffix);

      if (taxTypes.map(tax => tax.code).includes(subType)) {
        return `${intl.get(`reservations.transactions.chargesTypeAndTaxType.${label}`).d(label)} ${intl
          .get('reservations.transactions.headerLabels.payment')
          .d('Payment')} - ${intl.get(`reservations.transactions.paymentMethod.${methodSuffix}`).d(methodSuffix)}`;
      }

      if (label === null || label === undefined) {
        return `${intl.get('reservations.transactions.headerLabels.fullPayment').d('Full Payment')} - ${intl
          .get(`reservations.transactions.paymentMethod.${methodSuffix}`)
          .d(methodSuffix)}`;
      }

      return `${intl.get(`reservations.transactions.chargesTypeAndTaxType.${label}`).d(label)} - ${intl
        .get(`reservations.transactions.paymentMethod.${methodSuffix}`)
        .d(methodSuffix)}`;
    }

    return intl.get(`reservations.transactions.chargesTypeAndTaxType.${label}`).d(label);
  };

  getColumns = (currency = 'RM', checkIsAdminReadOnly = false) => {
    return [
      {
        title: intl.get('reservations.transactions.tableDetails.date').d('Date'),
        dataIndex: 'date',
        key: 'date',
        width: 120,
        render: date => <span>{moment(date).format('YYYY-MM-DD')}</span>
      },
      {
        title: intl.get('reservations.transactions.tableDetails.desc').d('Description'),
        dataIndex: 'subType',
        key: 'subType',
        width: 160,
        render: (subType, record) => {
          const label = subType === 'stripeCharges' ? 'Payment Charges' : this.getTransactionSubTypeLabel(record.type, subType, record.payment);

          return <span>{label}</span>;
        }
      },
      {
        title: intl.get('reservations.transactions.tableDetails.remarks').d('Remarks'),
        dataIndex: 'remarks',
        width: 100,
        key: 'remarks',
        render: remark => <span>{remark || '-'}</span>
      },
      {
        title: `${intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')} (${currency})`,
        dataIndex: 'amount',
        key: 'amount',
        width: 100,
        render: (amount, record) => {
          if (record.type === 'payment' || (record.type === 'deposit' && record.subType === 'collect')) {
            return <Row className={styles.amountReceived}>+ {Number(amount).toFixed(2)}</Row>;
          } else if (record.type === 'addon' || record.type === 'charges') {
            return <Row className={styles.amountCharged}>- {Number(amount + (record.paymentCharges || 0)).toFixed(2)}</Row>;
          } else if (record.type === 'tax' || (record.type === 'deposit' && record.subType === 'refund')) {
            return <Row className={styles.amountCharged}>- {Number(amount).toFixed(2)}</Row>;
          } else {
            return <span>{amount}</span>;
          }
        }
      },
      {
        title: intl.get('reservations.transactions.tableDetails.user').d('User'),
        dataIndex: 'user',
        key: 'user',
        width: 80,
        render: user => (user ? <p>{`${user.firstName} ${user.lastName}`}</p> : <p className={styles.centerText}>-</p>)
      },
      {
        title: intl.get('reservations.transactions.tableDetails.action').d('Action'),
        key: 'action',
        width: 80,
        render: (text, record) => {
          const canEdit = this.checkCanEdit(record.subType, record.isEditable);
          const canDelete = this.checkCanDelete(record.subType, record.isEditable);
          const editIconClassnames = canEdit ? styles.actionIcon : `${styles.actionIcon} ${styles.actionIconDisabled}`;
          const deleteIconClassnames = canDelete ? styles.actionIcon : `${styles.actionIcon} ${styles.actionIconDisabled}`;
          return (
            !(record.type === 'addon') &&
            !(record.subType === 'stripeCharges') && (
              <div>
                <span>
                  <Icon
                    type="edit"
                    className={editIconClassnames}
                    onClick={canEdit && !checkIsAdminReadOnly() ? () => this.handleOnClickedEditBtn(record) : () => {}}
                  />
                </span>
                <Divider type="vertical" />
                <span>
                  <Icon
                    type="delete"
                    className={deleteIconClassnames}
                    onClick={canDelete && !checkIsAdminReadOnly() ? () => this.handleOnClickedDeleteBtn(record) : () => {}}
                  />
                </span>
              </div>
            )
          );
        }
      }
    ];
  };

  handleOnClickedEditBtn = record => {
    this.setState({
      selectedRecord: record,
      isEditClicked: true
    });
  };

  handleOnClickedDeleteBtn = record => {
    this.setState({
      selectedRecord: record,
      isDeleteClicked: true
    });
  };

  handleOnEditCancel = () => {
    this.setState({
      isEditClicked: false
    });
  };

  handleOnDeleteCancel = () => {
    this.setState({
      isDeleteClicked: false
    });
  };

  handleOnTrxnUpdateSubmit = (event, form) => {
    const { selectedRecord } = this.state;
    event.stopPropagation();
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const formattedPayload = {
        date: values.trxnDate.format('YYYY-MM-DD'),
        amount: values.amount,
        remarks: values.remarks,
        id: selectedRecord._id,
        type: selectedRecord.type
      };

      if (!err) {
        Modal.confirm({
          title: intl.get('reservations.reservationDetails.placeholder.saveTitle').d('Are you sure you want to overwrite existing data?'),
          content: intl
            .get('reservations.reservationDetails.placeholder.saveContent2')
            .d('You will not be able to undo this action, but you may update it again.'),
          onOk() {
            putUpdateTrxn(formattedPayload)
              .then(() => {
                message.success(intl.get('reservations.reservationDetails.placeholder.updateSucess').d('Update Sucess !'));
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnDailyBreakdownUpdateSubmit = (event, form) => {
    const { selectedRecord } = this.state;
    event.stopPropagation();
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const formattedPayload = {
        id: selectedRecord._id,
        type: selectedRecord.type,
        dailyBreakdown: values
      };

      if (!err) {
        Modal.confirm({
          title: intl.get('reservations.reservationDetails.placeholder.saveTitle').d('Are you sure you want to overwrite existing data?'),
          content: intl
            .get('reservations.reservationDetails.placeholder.saveContent2')
            .d('You will not be able to undo this action, but you may update it again.'),
          onOk() {
            putUpdateTrxn(formattedPayload)
              .then(() => {
                message.success(intl.get('reservations.reservationDetails.placeholder.updateSucess').d('Update Sucess !'));
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnDeleteSubmit = () => {
    const { selectedRecord } = this.state;
    deleteTrxn({ id: selectedRecord._id, type: selectedRecord.type })
      .then(() => {
        message.success(intl.get('reservations.transactions.headerLabels.transactionDeleted').d('Transaction deleted!'));
        window.location.reload();
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  render() {
    const { transactions, currency, checkIsAdminReadOnly } = this.props;
    const { selectedRecord, isEditClicked, isDeleteClicked } = this.state;
    const summary = this.getSummary(transactions);

    console.log(transactions);

    const expandedRowRender = transaction => {
      if (transaction.dailyBreakdown) {
        const dailyBreakdown = this.getDailyBreakdown(transaction.dailyBreakdown, transaction.originalDailyBreakdown);
        const oriBreakdownCheck = Object.keys(transaction).includes('originalDailyBreakdown');

        const columns = [
          {
            title: intl.get('reservations.transactions.tableDetails.date').d('Date'),
            dataIndex: 'date',
            key: 'date'
          },
          oriBreakdownCheck
            ? {
                title: `${intl.get('reservations.transactions.tableDetails.originalAmount').d('Original Amount')} (${currency})`,
                dataIndex: 'originalAmount',
                key: 'originalAmount',
                render: originalAmount => {
                  return originalAmount ? <span className={styles.originalAmount}>- {Number(originalAmount).toFixed(2)}</span> : '-';
                }
              }
            : undefined,
          {
            title: `${intl.get('reservations.transactions.tableDetails.amount').d('Amount')} (${currency})`,
            dataIndex: 'amount',
            key: 'amount',
            render: amount => {
              return <span className={styles.amountCharged}>- {Number(amount).toFixed(2)}</span>;
            }
          }
        ].filter(k => k);
        return <Table columns={columns} dataSource={dailyBreakdown || []} pagination={false} />;
      }

      if (transaction.type === 'addon') {
        const columns = [
          {
            title: intl.get('reservations.transactions.tableDetails.date').d('Date'),
            dataIndex: 'date',
            key: 'date'
          },
          {
            title: intl.get('reservations.transactions.tableDetails.activity').d('Activity'),
            dataIndex: 'subType',
            key: 'subType'
          },

          {
            title: intl.get('reservations.transactions.tableDetails.pax').d('Pax'),
            dataIndex: 'pax',
            key: 'pax'
          },
          {
            title: `${intl.get('reservations.transactions.tableDetails.amount').d('Amount')} (${currency})`,
            dataIndex: 'amount',
            key: 'amount',
            render: amount => {
              return <span className={styles.amountCharged}>- {Number(amount).toFixed(2)}</span>;
            }
          }
        ].filter(k => k);
        return <Table columns={columns} dataSource={[transaction] || []} pagination={false} />;
      }

      if (transaction.payment && transaction.payment.cardNo) {
        return (
          <span>
            {intl.get('reservations.transactions.tableDetails.transactionCard').d('Transaction paid using credit card ')} {transaction.payment.cardNo}
          </span>
        );
      }
    };

    return (
      <Fragment>
        {transactions ? (
          <Table
            scroll={{ x: 700 }}
            rowKey={record => record._id}
            columns={this.getColumns(currency, checkIsAdminReadOnly)}
            dataSource={transactions.filter(t => !(t.subType === 'stripeCharges' && t.type === 'charges'))}
            expandedRowRender={expandedRowRender}
            rowClassName={transactions => transactions.dailyBreakdown || 'hidden-expand'}
            // scroll={{ y: 500 }} ** Temporary remove it **
            footer={() => <Footer summary={summary} currency={currency} />}
            pagination={false}
          />
        ) : (
          <Skeleton active />
        )}
        {!selectedRecord.dailyBreakdown && isEditClicked && (
          <Modal visible={isEditClicked} title={'Transaction'} footer={null} onCancel={this.handleOnEditCancel}>
            <TransactionModalForm record={selectedRecord} handleOnTrxnUpdateSubmit={this.handleOnTrxnUpdateSubmit} currency={currency} />
          </Modal>
        )}
        {selectedRecord.dailyBreakdown && isEditClicked && (
          <Modal
            visible={isEditClicked}
            title={intl.get('reservations.transactions.headerLabels.edit').d('Edit Daily Breakdown')}
            footer={null}
            onCancel={this.handleOnEditCancel}
          >
            <TransactionDailyBreakdownModalForm
              record={selectedRecord}
              handleOnDailyBreakdownUpdateSubmit={this.handleOnDailyBreakdownUpdateSubmit}
              currency={currency}
            />
          </Modal>
        )}
        {isDeleteClicked && (
          <Modal
            visible={isDeleteClicked}
            title={intl.get('reservations.transactions.headerLabels.transactions').d('Transactions')}
            onCancel={this.handleOnDeleteCancel}
            footer={[
              <Button key="back" onClick={this.handleOnDeleteCancel}>
                {intl.get('reservations.headerLabels.cancel').d('Cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={this.handleOnDeleteSubmit}>
                {intl.get('reservations.headerLabels.delete').d('Delete')}
              </Button>
            ]}
          >
            <DeleteModal record={selectedRecord} currency={currency} />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withAppContext(TransactionDetailsTable);
