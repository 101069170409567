import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { buildOverviewUri } from 'utils/routes';

import Logo from 'images/hostplatform-logo.png';
import SmallLogo from 'images/hostplatform-small-logo.png';

import styles from './HomeLogo.module.css';

import MsuLogo from 'images/msu-logo.jpg';
import { SPECIAL_HOST_IDS } from 'utils/constants';

const HomeLogo = ({ isCollapsed, hostId }) => {
  const [isSpecialHost, setIsSpecialHost] = useState(false);

  useEffect(() => {
    const SpecialHostCheck = SPECIAL_HOST_IDS.includes(hostId);

    setIsSpecialHost(SpecialHostCheck);
  }, [hostId]);

  return (
    <div className={styles.logoContainer}>
      <Link to={buildOverviewUri()}>
        {isSpecialHost ? (
          <img src={MsuLogo} alt="msu logo" style={{ width: '100%' }} />
        ) : (
          <img src={isCollapsed ? SmallLogo : Logo} alt="hostplatform logo" className={styles.logo} />
        )}
      </Link>
    </div>
  );
};

export default HomeLogo;
